import { Component, ElementRef, OnInit } from '@angular/core';
import { IsCloudService,ConfigService, LoginResponse } from '@ic-builder/data-access-iscloud';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ComponentsService, Outlet2Service,db, StoreAssetLocal } from '@ic-builder/is-base';
import { of } from 'rxjs';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ic-builder-svgcomp',
  templateUrl: './svgcomp.component.html',
  styleUrls: ['./svgcomp.component.scss']
})
export class SvgcompComponent implements OnInit {

  _url:string='';

  set url(value:string){
    if (value){
      this._url = value;
      const x = this.loaddata(this.el.nativeElement);
      //this.el.nativeElement.innerHTML = x;
      //const res = this.loaddata() //.subscribe(item => console.log(item));
      //res.subscribe((r) => this.el.nativeElement.innerHTML = r);
    }
  };

  constructor(private ic:IsCloudService,private store:Store,private comp:ComponentsService,private outlet:Outlet2Service,private config:ConfigService,private http: HttpClient,private el:ElementRef) { }

  ngOnInit(): void {
  //   if (this.url){
  //     const res = this.loaddata() //.subscribe(item => console.log(item));
  //     console.log(typeof res, res);

  //     res.subscribe((r) => this.el.nativeElement.innerHTML = r);
  //   } 
  // 
}

  loaddata(el){    
     if (!this._url.includes('dbassets')){
       const res = this.http.get(this.config.getDbUrl('') + this._url,{
         headers: new HttpHeaders().set('Content-Type', 'svg=image/svg+xml'),
         responseType: 'text'
       });
       return res.subscribe((r) => {
        el.innerHTML = r;
      });
     } else
     {
       db.dbassets.get({path:this._url}).then(item => {
          if (item){                        
            el.innerHTML = item.content
          } else
          {
            if (window.navigator.onLine){
              return this.ic.getData('dbasset', { path: this._url, role: '1', language: 'nl' }).pipe(
                tap((data) => {
                  if (data) {              
                    this.store.dispatch(new StoreAssetLocal({ path: data[0].path, hash: data[0].sessionid, content:data[0].content }));
                    el.innerHTML = data[0].content;
                  }
                })
              )          
            }
          }        
       });
    }



    // .pipe(
    //   map((response) => {
    //     console.log('response',response);  
    //     return response                  
    //   })
    // );
  
  }

}
