import { Overlay } from '@angular/cdk/overlay';
import { Clipboard } from '@angular/cdk/clipboard';
import { ComponentsService, EventMap } from './components.service';
import {
  Component,
  ComponentRef,
  Injectable,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  ViewContainerRef,
} from '@angular/core';
import { IsCloudService } from '@ic-builder/data-access-iscloud';
import {
  distinctUntilChanged,
  map,
  scan,
  shareReplay,
  switchMap,
  take,
  tap,
  filter,
  catchError,
} from 'rxjs/operators';
import {
  Action,
  createSelector,
  ofActionDispatched,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { IsMenuItem } from './ismenu.model';
import { WidgetsRegistryService } from '@ic-builder/widgets-registry';
import { Outlet2Component } from './outlet2/outlet2.component';
import { Isform2Component } from './isform2/isform2.component';
import { BehaviorSubject, EMPTY, from, Observable, of } from 'rxjs';
import { SubSink } from 'subsink';
import set from 'lodash/fp/set';
import get from 'lodash/fp/get';
import {
  flattenTree,
  generateTreeChildPropertyValue,
  getTreeChildPath,
  treeifyArray,
} from '@ic-builder/utils';
import { cloneDeep, getOr } from 'lodash/fp';
import produce from 'immer';
import { IIsComponent, FlatIIsComponent } from './iis-component.interface';
import {
  AddWidget,
  ClearCanvas,
  ClearOutlet,
  DelComponent,
  DeleteWidgetOutlet,
  ExportToClipBoard,
  InsertComponent,
  LoadComponent,
  LoadFormInEditor,
  NewComponent,
  NewId,
  NewMenu,
  NewOutletId,
  PasteFromClipBoard,
  RefreshComponent,
  RemoveComponent,
  SaveReport,
  SaveTree,
  SaveWizard,
  SetComponent,
  SetComponentSaveState,
  SetEventHandler,
  SetFormConnectionId,
  SetInDesigner,
  SetMenuItem,
  RefreshAllComponents,
  SetNavigator,
  TrackLoading,
  UnLoadComponent,
  UpdateProperty,
  UpdateView,
  StoreAssetLocal,  
  PreLoadComponents
} from './base.actions';
import { fromPairs } from 'lodash/fp';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { IIsAppConfig } from '..';
//import { ConnectWebSocket } from '@ngxs/websocket-plugin';
import { db, ComponentList } from './db';
import { liveQuery } from 'dexie';
import { SpeedTestService } from 'libs/data-access-iscloud/src/lib/speed-test.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UniquenbrService } from './uniquenbr.service';

let outletServiceInstanceNr = 0;

export interface OutletServiceState {
  //  newcompid:number,
  newoutletid: number;
  newid: number | undefined;
  components: Map<number, IIsComponent>;
  functions: Map<number, Map<string, Function>>;
  menus: Map<number, IsMenuItem>;
  contextid: number;
  unimonitor: boolean;
  comploaded: Map<number, boolean>;
  savestate: number;
  loaderrors: any[];
  indesigner: boolean;
}

@State<OutletServiceState>({
  name: 'outletstate',
  defaults: {
    //newcompid:1000,
    newoutletid: 1000,
    newid: undefined,
    components: new Map<number, IIsComponent>(),
    functions: new Map<number, Map<string, Function>>(),
    menus: new Map<number, IsMenuItem>(),
    contextid: 0,
    unimonitor: true,
    comploaded: new Map<number, boolean>(),
    savestate: 0,
    loaderrors: [],
    indesigner: false,
  },
})
@Injectable()
export class Outlet2Service implements OnDestroy {

  public offline = window.navigator.onLine;
  public loadofflinefirst = false;

  private componentTree: Map<number, IIsComponent> = new Map<
    number,
    IIsComponent
  >();
  #subs = new SubSink();
  private components: Map<number, IIsComponent> = new Map<
    number,
    IIsComponent
  >();

  static outletsSubject = new BehaviorSubject<{
    id: number;
    component?: ComponentRef<Outlet2Component>;
  }>({
    id: 0,
  });

  static outlets$ = Outlet2Service.outletsSubject.pipe(
    // tap((v) => {
    //   console.log(`%cClass: Outlet2Service, Function: outlets$.tap(v): `, 'color: black;', v);
    // }),
    scan<
      { id: number; component: ComponentRef<Outlet2Component> },
      Map<number, ComponentRef<Outlet2Component>>
    >((acc, { id, component }) => {
      //if (component) {
      acc.set(id, component);
      //} else {
      //  acc.delete(id);
      //}
      return acc;
    }, new Map()),
    shareReplay()
  );
  
  editid = -1_000_000;
  deletewidgets = [];
  _uniqueId = -3000;
  routersnapshot:ActivatedRouteSnapshot;
  connectionid: number = 0;
  commandlist = [];
  appconfig: IIsAppConfig | null = null;
  renderer: Renderer2;
  componentList$: Observable<ComponentList[]> | null = null;
  serveronline:boolean=true;

  private _outletServiceInstanceNr: number;

  get outletServiceInstanceNr() {
    return this._outletServiceInstanceNr;
  }

  get uniqueId() {
    //console.log('getuniqueid : ',this.outletServiceInstanceNr,' ',this._uniqueId--);
    //return this._uniqueId;
    return this.uniquenbr.uniqueId;
  }

  constructor(
    private compService: ComponentsService,
    private ic: IsCloudService,
    private store: Store,
    private overlay: Overlay,
    private widgetRegistry: WidgetsRegistryService,
    private _renderer: RendererFactory2,
    private speedtest: SpeedTestService,
    private clipboard: Clipboard,
    private router:ActivatedRoute,
    private uniquenbr:UniquenbrService
  ) {
    this._outletServiceInstanceNr = outletServiceInstanceNr++;
    console.log('outlet service ', this.outletServiceInstanceNr);
    this.renderer = _renderer.createRenderer(null, null);
    // console.trace(
    //   `%cClass: Outlet2Service, Function: constructor(this.outletServiceInstanceNr): `,
    //   'color: black;',
    //   this.outletServiceInstanceNr
    // );
    this.#subs.sink = Outlet2Service.componentTree$.subscribe((tree) => {
      this.componentTree = tree;
      // console.log(
      //   `%cClass: Outlet2Service, Function: componentTree$.next(this.outletServiceInstanceNr, tree): `,
      //   'color: black;',
      //   this.outletServiceInstanceNr,
      //   tree
      // );
    });
  }

  private static formsSubject = new BehaviorSubject<{
    id: number;
    component?: ComponentRef<Isform2Component>;
  }>({
    id: 0,
  });

  private static forms$ = Outlet2Service.formsSubject.pipe(
    // tap((v) => {
    //   console.log(`%cClass: Outlet2Service, Function: forms$.tap(v): `, 'color: black;', v);
    // }),
    scan<
      { id: number; component: ComponentRef<Isform2Component> },
      Map<number, ComponentRef<Isform2Component>>
    >((acc, { id, component }) => {
      if (component) {
        acc.set(id, component);
      } else {
        acc.delete(id);
      }
      return acc;
    }, new Map()),
    shareReplay()
  );


  private static componentTreeSubject = new BehaviorSubject<{
    id: number;
    component?: IIsComponent;
  }>({
    id: 0,
  });

  private static componentTree$ = Outlet2Service.componentTreeSubject.pipe(
    // tap((v) => {
    //   console.log(`%cClass: Outlet2Service, Function: componentTree$.tap(v): `, 'color: black;', v);
    // }),
    scan<{ id: number; component: IIsComponent }, Map<number, IIsComponent>>(
      (acc, { id, component }) => {
        if (component) {
          acc.set(id, component);
        } else {
          acc.delete(id);
        }
        return acc;
      },
      new Map()
    ),
    shareReplay()
  );

  @Selector()
  public static getNewId(state: OutletServiceState) {
    return state.newid;
  }

  @Selector()
  public static getInDesigner(state: OutletServiceState) {
    return state.indesigner;
  }

  @Selector()
  public static savestate(state: OutletServiceState) {
    return state.savestate;
  }

  @Selector()
  public static contextid(state: OutletServiceState) {
    return state.contextid;
  }

  static formasnavigator(id: number): Observable<[IIsComponent] | null> {
    return Outlet2Service.componentTree$.pipe(
      map((componentTreeMap) => componentTreeMap.get(id)),
      distinctUntilChanged(),
      map((component) =>
        component ? [{ ...cloneDeep(component), state: 0 }] : null
      )
    );
  }

  static outletx(id: number): Observable<ComponentRef<Outlet2Component>> {
    return this.outlets$.pipe(map((outletsMap) => outletsMap.get(id)));
  }

  static getinterfacecomponent(id: number) {
    return createSelector([Outlet2Service], (state: OutletServiceState) => {
      return state.components.get(id);
    });
  }

  static menu(id: number) {
    return createSelector([Outlet2Service], (state: OutletServiceState) => {
      return state.menus.get(Number(id));
    });
  }

  makeUniqueIds(flattenFormDefinition: Record<number, FlatIIsComponent>): {
    flattenDefinition: Record<number, FlatIIsComponent>;
    dictionary: Map<number, number>;
    dict: any;
  } {
    const idMap = new Map<number, number>(
      (<Array<number>>(<unknown>Object.keys(flattenFormDefinition))).map(
        (k) => [Number(k), this.uniqueId]
      )
    );

    const x = {};

    (<Array<number>>(<unknown>Object.keys(flattenFormDefinition))).map(
      (k) => (x[k.toString()] = this.uniqueId)
    );
    return {
      flattenDefinition: Object.values(flattenFormDefinition).reduce(
        (acc, v) => ({
          ...acc,
          [idMap.get(v.id)]: {
            ...v,
            id: idMap.get(v.id),
            templateid: v.id,
            parentid: idMap.get(v.parentid) ?? v.parentid,
            children: v.children?.map((c) => idMap.get(c)),
          },
        }),
        {}
      ),
      dictionary: idMap,
      dict: x,
    };
  }

  private flatCompIds(formDefinition: IIsComponent | undefined): Array<number> {
    if (!formDefinition) {
      return undefined;
    }
    const workArray: Array<IIsComponent> = [cloneDeep(formDefinition)];

    const result = [];

    while (workArray.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const w = workArray.shift()!;
      const { id, children } = w;
      if (Array.isArray(children)) {
        const updatedContent = (<Array<IIsComponent>>children).map(
          (e, index) => ({
            ...e,
            parentid: id,
            flexsize: 1,
            flexorder: index + 1,
          })
        );
        workArray.push(...updatedContent);
        (w as unknown as FlatIIsComponent).children = (<Array<IIsComponent>>(
          children
        )).map((e) => e.id);
      }
      result.push(id);
    }

    return result;
  }

  flattenFormDefinition(
    formDefinition: IIsComponent | undefined
  ): Record<number, FlatIIsComponent> {
    if (!formDefinition) {
      return undefined;
    }
    const workArray: Array<IIsComponent> = [cloneDeep(formDefinition)];

    const result = [];

    while (workArray.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const w = workArray.shift()!;
      const { id, children } = w;
      if (Array.isArray(children)) {
        const updatedContent = (<Array<IIsComponent>>children).map(
          (e, index) => ({
            ...e,
            parentid: id,
            flexsize: 1,
            flexorder: index + 1,
          })
        );
        workArray.push(...updatedContent);
        (w as unknown as FlatIIsComponent).children = (<Array<IIsComponent>>(
          children
        )).map((e) => e.id);
      }
      result.push([id, w]);
    }

    const res: Record<number, FlatIIsComponent> = fromPairs(result);
    //console.log(`%cClass: FormDefinitionState, Function: flattenFormDefinition(res): `, 'color: black;', res);
    return res;
  }

  addWidget2(widget: IIsComponent| undefined, parentId:  number | null) {
    const { flattenDefinition,  dict } = this.makeUniqueIds(
      this.flattenFormDefinition({ ...widget, parentid: parentId })
    );

    const treeDefinition: IIsComponent = {
      id: 0,
      parentid: 0,
      name: '',
      type: 'isform',
    };
    const rootFormElementId = parentId;

    const nextState = {
      flattenDefinition,
      parentId,
      treeDefinition,
      rootFormElementId,
      dict,
    };
    const patch = produce(nextState, (draft) => {
      draft.treeDefinition = this.convertToNestedDefinition(
        draft.flattenDefinition,
        draft.rootFormElementId
      );
      draft.treeDefinition.dict = dict;
    });

    return patch.treeDefinition;
  }

  hasOwnPropertyHelper<X extends unknown, Y extends PropertyKey>(
    obj: X,
    prop: Y
  ): obj is X & Record<Y, unknown> {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }

  convertToNestedDefinition(
    flatFormDefinition: Record<number, FlatIIsComponent>,
    definitionId: number
  ): IIsComponent {
    const ffd = cloneDeep(flatFormDefinition);
    Object.values(ffd).forEach((el: FlatIIsComponent) => {
      if (
        this.hasOwnPropertyHelper(el, 'children') &&
        Array.isArray(el.children)
      ) {
        const { children } = el;
        const refContent = children.map<IIsComponent>(
          (idx) => <IIsComponent>(<unknown>ffd[idx])
        );
        const refContentAdj = refContent.map<IIsComponent>((item, index) => {
          if (!item.flexorder) {
            item['flexorder'] = index + 1;
            item['flexsize'] = 1;
          }
          return <IIsComponent>item;
        });
        refContentAdj.sort((a, b) => {
          return a?.flexorder - b?.flexorder;
        });

        children.splice(0);
        (<Array<IIsComponent>>(<unknown>children)).push(...refContentAdj);
      }
    });
    let temp =  <IIsComponent>(<unknown>Object.values(ffd).find((c) => c.parentid === null));
    if (temp) return temp;

    temp = <IIsComponent>(<unknown>Object.values(ffd)[0])

    return temp //.find((c) => c.parentid === null));
  }

  getComponentById(id: number) {
    return this.components.get(id);
  }

  setIIsComponent(structure: IIsComponent) {
    this.components.set(structure.id, structure);
  }

  getComponentTreeById(id: number) {
    return this.componentTree.get(id);
  }

  getComponentTree(id: number) {
    let cmp;
    this.componentTree.forEach((item) => {
      if (item.id === id) {
        cmp = item;
      }
    });
    return cmp;
  }

  createNewComponent(
    outlet: number,
    component: Omit<IIsComponent, 'id' | 'parentid'>
  ) {
    return this.ic
      .getData('getid', { key: 'gn_forms', number: 2, account: 10 })
      .subscribe(async (data) => {
        if (!data?.length) {
          return;
        }
        const [{ id }] = data;
        const parentId = id - 1;

        // let parentid = payload.parentid? payload.parentid: this.uniqueId;
        // if (payload.config) {
        //   config = { ...config, ...payload.config };
        // }
        const newF: IIsComponent = {
          ...component,
          id,
          parentid: parentId,
        };
        this.insertFormIntoOutlet(outlet, newF);
        //this.store.dispatch(new SetCurrentFormDefinition(component));
        this.store.dispatch({
          type: '[FormDefinition.SetCurrentFormDefinition]',
          payload: newF,
        });
      });
  }

  map(a) {
    return map(a);
  }

  tap(a) {
    return tap(a);
  }

  catchError(a) {
    return catchError(a);
  }

  filter(a) {
    return filter(a);
  }

  getComponentById$(id: number) {
    return Outlet2Service.componentTree$.pipe(
      map((componentTreeMap) => componentTreeMap.get(id))
    );
  }

  setComponentById$(id: number, component: any) {
    return Outlet2Service.componentTreeSubject.next({
      id: id,
      component: component,
    });
  }

  async formatStructure(
    structure: IIsComponent,
    functions,
    editmode,
    runcd?,
    exists?,
    data?
  ) {
    if (this.editid === structure.id || editmode) {
      editmode = true;
      exists = 1;
    }
    this.components.set(structure.id, structure);
    const toInsert = [];
    if (structure.children) {
      if (structure.sizes == null) {
        structure.sizes = [];
      }
      for (const child of structure.children) {
        const id = child.id;

        structure.sizes.push({
          id: id,
          parentid: structure.id,
        });
        toInsert.push(
          await this.formatStructure(
            child,
            functions,
            editmode,
            runcd,
            exists,
            data
          )
        );
      }
    }
    const compRef = await this.compService.createWidget(
      structure,
      runcd,
      exists,
      data
    );
    if (!compRef) {
      return null;
    }

    if (functions) {
      const funcs = functions.get(structure.id);
      if (funcs) {
        for (const [key, func] of funcs) {
          compRef[key] = func;
        }
      }
    }

    if (toInsert.length > 0) {
      //if (Array.isArray(compRef.instance.children)){
      const reference = structure.config
        ? !structure.config.childasreference
          ? 0
          : 1
        : 0;
      if (
        !reference &&
        compRef.instance.type != 'ismenu2item' &&
        compRef.instance.type != 'ismenu2' &&
        compRef.instance.type != 'iscontextoverlay'
      ) {
        if (compRef.instance.children != null) {
          compRef.instance.editmode = editmode;

          if (compRef.instance.type == 'ispanel') {
            const containers = compRef.instance.children.toArray();

            for (const insert of toInsert) {
              containers[0].insert(insert?.hostView);
            }
          } else {
            const containers = compRef.instance.children.toArray();
            for (const insert of toInsert) {
              containers[0].insert(insert?.hostView);
            }
          }
        }
      }
    }
    if (!runcd) {
      compRef.changeDetectorRef.detectChanges();
    }
    return compRef;
  }

  async formatStructure2(
    structure: IIsComponent,
    functions: Map<number, Map<string, Function>> | null,
    editmode: boolean | null,
    runcd?: number,
    exists?: number,
    data?: any,
    trackchildrefs?: number | undefined,
    classasid?: number | undefined,
    owner?: Component
  ) {
    if (!structure) return null;
    if (this.editid === structure?.id || editmode) {
      editmode = true;
      exists = 1;
    }

    this.components.set(structure.id, structure);

    const compRef = await this.compService.createWidget(
      structure,
      runcd,
      exists,
      data,
      classasid
    );

    if (!owner) {
      // owner = compRef?.instance;
    }

    if (!compRef) {
      return null;
    }

    const toInsert = [];
    if (structure.children) {
      for (const child of structure.children) {
        const c = await this.formatStructure2(
          child,
          functions,
          editmode,
          runcd,
          exists,
          data,
          trackchildrefs,
          classasid
          //  owner
        );
        toInsert.push(c);
        if (trackchildrefs) {
          if (c) {
            if (!compRef['ischildren']) {
              compRef['ischildren'] = [];
              compRef.instance.ischildren = compRef['ischildren'];
            }
            compRef['ischildren'].push(c);

            compRef.instance[c.instance.name] = c.instance;
            c.instance['isparent'] = compRef.instance;
            c.instance['isowner'] = owner;
          }
        }
      }

      if (compRef.instance.onLoaded) {
        if (typeof compRef.instance.onLoaded == 'string') {
          compRef.instance.onLoaded = new Function(compRef.instance.onLoaded);
        }
        compRef.instance.onLoaded();
      }
    }

    const funcs = this.compService.functions.get(
      structure.templateid ?? structure.id
    );

    if (funcs) {
      for (const [key, func] of funcs) {
        
        compRef.instance[key] = (...args: any[]) => { 
          try {
            return func.bind(compRef.instance)(...args)
          } catch (e) {
            throw new Error(
              `Failed ${structure.templateid}, on Function ${key} with error ${e}`
            )
          }
         };
      }
      // Check custom onInit only if it can be loaded
    }

    const messages = this.compService.messagehandlers.get(
      structure.templateid ?? structure.id
    );

    if (messages) {
      for (const [key, message] of messages) {
        //console.log('resgister iMessage subscriber ', message);
        this.store
          .select((state) => state.isapplicationstate.messages)
          .pipe(
            filter((mes) => {
              return mes?.name === key;
            })
          )
          .subscribe((mes) => {
            //console.log('iMessage subscriber ', mes);
            if (mes) {
              compRef.instance[key]({
                sender: mes.sender,
                responder: mes.responder,
              });
            }
          });
      }
    }

    const validators = this.compService.validators.get(
      structure.templateid ?? structure.id
    );
    if (validators) {
      for (const [key, func] of validators) {
        compRef.instance[key] = (
          control: AbstractControl
        ): ValidatorFn | null => {
          return func(control);
        };
      }
      // Check custom onInit only if it can be loaded
    }

    const setters = this.compService.setters.get(
      structure.templateid ?? structure.id
    );
    if (setters) {
      for (const [key, func] of setters) {      
        
        const set_fn = (arg: any) => {
          try {
            func.bind(compRef.instance)(arg);
          } catch(e) {
            throw new Error(
              `Failed ${compRef.instance.name}, on setter ${key} with error ${e}`
            )
          }
        }

        Object.defineProperty(compRef.instance, key, { //<- This object is called a "property descriptor".          
          set: set_fn,
          get: () => { return compRef.instance.data?compRef.instance.data[key]:null }
          })
      }
      // set the inital property value
      for (const [key] of setters) {
        try {
        compRef.instance[key] = structure.config[key];  
        } catch(error){
          console.error(compRef.instance.id.toString()+' '+compRef.instance.name+' '+compRef.instance.type+' property setter : ',[key], ' contains error : ',error);

          this.store?.dispatch({
            type: '[AppError]',
            payload: {
              code: 1001,
              source: compRef.instance.id.toString()+' '+compRef.instance.name+' '+compRef.instance.type,              
              message: 'property setter : '+[key]+ ' contains error : '+error,
              level: 'error',
            },
          });

        }
      }
    }
    
    // Check custom onInit only if it can be loaded
    if (toInsert.length > 0) {
      const reference = structure.config
        ? !structure.config.childasreference
          ? 0
          : 1
        : 0;

      if (
        !reference &&
        compRef.instance.type != 'ismenuhandler' &&
        compRef.instance.type != 'ismenuhandleritem' &&
        compRef.instance.type != 'iscontextoverlay'
      ) {
        if (compRef?.instance.children != null) {
          compRef.instance.editmode = editmode;

          if (
            [
              'ispanel',
              'isformgroup',
              'iswizpage',
              'isreportpage',
              'ismasterdata',
              'ispageheader',
              'ispagefooter',
              'isgroupheader',
              'isgroupfooter',
              'isform',
              'isreportdatapage',
              'isreporttitle',
              'isreportsummary',
              'ispreviewpage',
              'isband',
              'isheader',
              'isfooter',
              'isdetaildata',
              'ischild',
              'ischeckboxpanel',
              'menuhandler3',
            ].includes(compRef.instance.type)
          ) {
            const firstContainer = compRef.instance.children.first;

            for (const insert of toInsert) {
              firstContainer.insert(insert?.hostView);
            }
          }
        }
      }
    }
    if (!runcd) {
      compRef?.changeDetectorRef.detectChanges();
    }
    return compRef;
  }

  seteventsoncomponent(component: any) {
    if (component.events) {
      const event = produce(component.events, (draft: any) => {
        for (const ev of draft) {
          ev.parentid = component.id;
          try {
            const func = new Function(ev.config.code);
            const eventmap = new EventMap(
              ev.name,
              ev.config.type,
              func,
              ev.id,
              this.overlay,
              this.widgetRegistry,
              ev.parentid,
              ev.config.specific,
              ev.config.selectall,
              this.compService,
              this.store,
              this.renderer
            );
            this.compService.setEvent(component.id, eventmap, ev.config.ref);
          } catch (err) {
            console.error(
              'Error in eventcode : ',
              ev.name,
              ' ',
              ev.config.code
            );
          }
        }
      });
    }
    if (component.children) {
      component.children.map((child: any) => {
        if (child.events) {
          this.seteventsoncomponent(child);
        }
      });
    }
  }

  async cloneComponent(
    id: number,
    compid: number,
    masterid: number,
    configfunction: any,
    data: any = null
  ) {
    const cmp2tree = this.getComponentTreeById(id);
  }

  async cloneComp(id: number) {
    const cmp2tree = this.getComponentTreeById(id);
    const comptree = this.addWidget2(cmp2tree, null);
    return { tree: comptree, ids: this.flatCompIds(comptree) };
  }

  replaceIndices(comptree:IIsComponent, subtree: IIsComponent, config: any) :IIsComponent {

    if (!subtree.config) return subtree;

    const findOrigin = (cmp, id) => {
      if (cmp.templateid === id) return cmp.id

      for (let child of cmp.children ? cmp.children : []) {
        const res =  findOrigin(child, id);
      
        if (res) return res;
      }

      return null;
    }

    const alter: any = {};

    if (subtree.config.dsname) {
      alter.dsname = (config?.prefix ? config?.prefix : '') + subtree.config.dsname;
    }

    ['formgroupdef', 'masterdetail', 'formGroupItems'].forEach((prop) => {
      if (subtree.config[prop]) {
        const temp = subtree.config[prop].map((f) => {
          
          const k = prop === 'formgroupdef' ? 'key' : 'controlname';
          
          const reg = new RegExp("{{.*}}");
          
          const result = f[k].match(reg);
          
          if (result) {
            let [m] = result

            m = m.substring(2, m.length - 2);

            return { ...f, [k] : f[k].replace(reg, config[m]) }
          }

          const [id, name] = f[k].split('.');
          const tempId = findOrigin(comptree, Number(id))
  
          return { ...f, [k]: `${tempId ? tempId : id}.${name}` }
        });
        
        alter[prop] = temp;
      }
    })


    return {
      ...subtree,
      config: {
        ...subtree.config, ...alter
      },
      children: subtree.children?.map((c) => this.replaceIndices(comptree, c, config)),
    };
  }

  async copyComponent(id: number, compid: number, masterid: number, configfunction: any, data: any = null, isparent: any = null) {
    const cmp2tree = this.getComponentTreeById(id);

    let comptree = this.addWidget2(cmp2tree, compid);
    

    const component = this.flatCompIds(comptree);

    comptree = this.replaceIndices(comptree, comptree, configfunction ? configfunction() : null);
    // if (comptree.config?.formgroupdef){

    //     const findOrigin = (cmp, id) => {
    //       if (cmp.templateid === id) return cmp.id

    //       for (let child of cmp.children ? cmp.children : []) {
    //         const res =  findOrigin(child, id);
          
    //         if (res) return res;
    //       }

    //       return null;
    //     }

    //     const formgroupdef = comptree.config.formgroupdef.map((f) => {
    //       const [id, name] = f.key.split('.');

    //       const tempId = findOrigin(comptree, Number(id))

    //       return { ...f, key: `${tempId}.${name}` }
    //     });

    //     comptree = {
    //       ...comptree,
    //       config: {
    //         ...comptree.config, formgroupdef
    //       }
    //     }
    // }
    if (data && comptree.config ){
      Object.assign(comptree.config, {...comptree.config,...data});
    }


    const copyRef = await this.formatStructure2(comptree, this.compService.functions, null, 0, 1, data, 1);
    if (copyRef && isparent){
      copyRef.instance['isparent'] = isparent;
    }

    component.forEach((cmp) => {
      const cmpRef = this.compService.widgets.get(cmp);
      try {
        if (cmpRef?.instance?.afterformReady) {
          cmpRef?.instance?.afterformReady(cmpRef?.instance);
        }
      } catch (error) {
        console.error('error afterformReady ', cmpRef, ' ', error);
        this.store?.dispatch({
          type: '[AppError]',
          payload: {
            code: 1000,
            source: 'afterformReady : ' + cmpRef?.instance.name + cmpRef?.instance.name.toString(),
            message: error,
            level: 'error',
          },
        });
      }

      if (cmpRef?.instance.aftercloneReady) {
        cmpRef?.instance?.aftercloneReady(cmpRef?.instance, masterid, configfunction ?? configfunction());
      }
    });

    this.compService.functions.delete(comptree.id);

    //celRef.location.nativeElement.setAttribute('gridid', this.gridid);
    /* see if we can move this to the prototype of the isform */
    this.seteventsoncomponent(comptree);
    return { renderedtree: copyRef, form: comptree };
  }

  loadOutlet(idx: number) {
    //let outlet$ = this.loaddata(idx,137);
  }

  loadWizard(componentlist: any[]) {
    const wizard = componentlist.shift();
    wizard.config['componentlist'] = componentlist;
    return this.compService.createWidget(wizard);
  }

  compileMenuTree(componentlist: string | any[]) {
    let prevlevel = 0;
    let stack, prev: IsMenuItem;
    let teller = 0;
    teller = 0;

    let cmp = componentlist[teller];
    //console.log(cmp.component, ' ', cmp.aantitem, ' ', cmp.level);
    let c = new IsMenuItem(cmp);
    if (!c.children) {
      c.children = [];
    }
    stack = c;
    const data = c;
    teller = 1;
    while (teller < componentlist.length) {
      cmp = componentlist[teller];
      //console.log(cmp.component, ' ', cmp.aantitem, ' ', cmp.level);
      c = new IsMenuItem(cmp);
      if (cmp.aantitem > 0) {
        if (!c.children) {
          c.children = [];
        }
        if (cmp.level < prevlevel) {
          stack = prev;
          stack.children.push(c);
          stack = c;
        } else if (cmp.level > prevlevel) {
          stack.children.push(c);
          prev = stack;
          stack = c;
        } else {
          stack.children.push(c);
          stack = c;
        }
      } else {
        stack.children.push(c);
      }
      prevlevel = cmp.level;
      teller++;
    }
    return data;
  }

  compileFactoryTree(componentlist: Array<IIsComponent>): IIsComponent {
    const [tree] = treeifyArray(componentlist, {
      idProperty: 'id',
      parentIdProperty: 'parentid',
      childrenProperty: 'children',
    });
    return tree;
  }

  public async insertFormIntoOutlet(
    outletId: number,
    form: IIsComponent,
    loadas?: number
  ) {
    const renderedTree = await this.formatStructure2(
      form,
      null,
      true,
      0,
      0,
      null,
      1
    );
    Outlet2Service.outletsSubject.next({
      id: outletId,
      component: renderedTree,
    });
    Outlet2Service.formsSubject.next({ id: outletId, component: renderedTree });
    Outlet2Service.formsSubject.next({
      id: form.parentid,
      component: renderedTree,
    });
    // Add component to componenttree
    this.components.set(form.id, form);
    Outlet2Service.componentTreeSubject.next({
      id: form.parentid,
      component: form,
    });
    // Update the component to the canvas
    Outlet2Service.componentTreeSubject.next({ id: form.id, component: form });
    if (loadas) {
      Outlet2Service.formsSubject.next({ id: loadas, component: renderedTree });
      Outlet2Service.componentTreeSubject.next({ id: loadas, component: form });
    }
  }

  @Action(SetEventHandler)
  setEvent(
    ctx: StateContext<OutletServiceState>,
    { payload }: SetEventHandler
  ) {
    return Outlet2Service.componentTree$.pipe(
      take(1),
      map((treeMap) => treeMap.get(payload.formid)),
      tap((componentTree) => {
        const path = getTreeChildPath(
          componentTree,
          { idProperty: 'id', childrenProperty: 'children' },
          payload.compid
        );
        if (path) {
          const newComponentTree = set(
            [...path, 'events'],
            payload.events,
            componentTree
          );
          this.componentTree.set(payload.formid, newComponentTree);
          this.componentTree.set(newComponentTree.parentid, newComponentTree);
          Outlet2Service.componentTreeSubject.next({
            id: payload.formid,
            component: newComponentTree,
          });
        }
      })
    );
  }

  @Action(UpdateProperty)
  updateProperty(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: UpdateProperty
  ) {
    console.log(`%cClass: Outlet2Service, Function: updateProperty(payload): `, 'color: black;', payload);
    return Outlet2Service.componentTree$.pipe(
      map((componentTreeMap) => componentTreeMap.get(1_000_000)),
      take(1),
      tap((componentTree) => {
        let tree = componentTree;
        const componentPath = getTreeChildPath(
          componentTree,
          { idProperty: 'id', childrenProperty: 'children' },
          payload.compid
        );
        if (!componentPath) {
          return; // component not found
        }
        const parentPath = componentPath.slice(0, -2);
        const splitpath = payload.path.split('.');
        if (splitpath.includes('config') || payload.path === 'name') {
          const widget = this.compService.widgets.get(payload.compid);
          if (widget) {
            const [changedProperty] = splitpath.slice(-1);
            if (
              typeof widget.instance[`${changedProperty}ValueChanges`] ===
              'function'
            ) {
              widget.instance[`${changedProperty}ValueChanges`](payload.value);
            } else {
              // potential bug:
              // if splitpath = ['country', 'config', 'area', 'city']
              // widget.instance['city'] = payload.value
              // instead of widget.instance['country']['config']['area']['city'] = payload.value
              widget.instance[changedProperty] = payload.value;
            }
          }
        }

        if (payload.path === 'flexorder') {
          const siblingsPath = [...parentPath, 'children'];
          const oldFlexOrder = get([...componentPath, 'flexorder'], tree);
          const newFlexOrder = Number(payload.value);
          tree = set(
            siblingsPath,
            get(siblingsPath, tree).map((sibling) => {
              if (sibling.id === payload.compid) {
                return sibling;
              }
              let updatedSibling = sibling;
              if (oldFlexOrder < newFlexOrder) {
                if (
                  sibling.flexorder > oldFlexOrder &&
                  sibling.flexorder <= newFlexOrder
                ) {
                  updatedSibling = set(
                    ['flexorder'],
                    sibling.flexorder - 1,
                    sibling
                  );
                }
              } else if (oldFlexOrder > newFlexOrder) {
                if (
                  sibling.flexorder >= newFlexOrder &&
                  sibling.flexorder < oldFlexOrder
                ) {
                  updatedSibling = set(
                    ['flexorder'],
                    sibling.flexorder + 1,
                    sibling
                  );
                }
              }
              return updatedSibling;
            }),
            tree
          );
        }
        tree = set(
          componentPath,
          set(splitpath, payload.value, get(componentPath, tree)),
          tree
        );
        tree = set(['state'], 1, tree);

        Outlet2Service.componentTreeSubject.next({
          id: tree.parentid,
          component: tree,
        });
        Outlet2Service.componentTreeSubject.next({
          id: 1_000_000,
          component: tree,
        });

        patchState({
          savestate: 1,
        });
      })
    );
  }

  @Action(SetMenuItem)
  setMenuItem(
    ctx: StateContext<OutletServiceState>,
    { payload, render }: SetMenuItem
  ) {
    if (render) {
      this.compService.createWidget(payload.add);
    }

    return Outlet2Service.componentTree$.pipe(
      take(1),
      map((treeMap) => treeMap.get(payload.formid)),
      tap((componentTree) => {
        const path = getTreeChildPath(
          componentTree,
          { idProperty: 'id', childrenProperty: 'children' },
          payload.parentid
        );
        if (path) {
          const oldChildren = get([...path, 'children'], componentTree);
          const newComponentTree = set(
            [...path, 'children'],
            [...(oldChildren ?? []), payload.add],
            componentTree
          );
          Outlet2Service.componentTreeSubject.next({
            id: payload.formid,
            component: newComponentTree,
          });
        }
      })
    );
  }

  emptyform(parentid, newcompid: number): IIsComponent {
    return {
      id: newcompid,
      name: 'new Form ' + newcompid.toString(),
      flexsize: 100,
      flexorder: 1,
      parentid: parentid,
      formgroup: 0,
      type: 'isform',
      config: { gutterSize: 4, direction: 'vertical', size: 100 },
      attribs: { xxx: 0 },
      //place:{"left":0,"top":0,"width":"100%","height":"100%"},
      children: [],
      sizes: [],
    };
  }

  @Action(NewOutletId)
  newOutletid(
    { patchState }: StateContext<OutletServiceState>,
    { payload }: NewOutletId
  ) {
    patchState({
      newoutletid: payload,
    });
  }

  @Action(SetInDesigner)
  setInDesigner(
    { patchState }: StateContext<OutletServiceState>,
    { payload }: SetInDesigner
  ) {
    patchState({
      indesigner: payload,
    });
  }

  @Action(DelComponent)
  delComponent(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: DelComponent
  ) {
    const state = getState();
    if (this.deletewidgets.length > 0) {
      return this.ic
        .writeData('app.delcomponent', {
          account: 10,
          data: this.deletewidgets,
          role: '1',
          language: 'nl',
          logging: { unimonitor: state.unimonitor },
        },null,false)
        .pipe(
          tap((tree) => {
            this.deletewidgets = [];
          })
        );
    }
  }

  @Action(ClearCanvas)
  clearCanvas() {
    Outlet2Service.outletsSubject.next({ id: -10000, component: undefined });
  }

  @Action(NewMenu)
  async newMenu(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: NewMenu
  ) {
    //console.log('load component',payload);
    const state = getState();

    //let newcompid = this.uniqueId;
    let id;

    if (payload != null) {
      const parentid = payload.parentid ?? this.uniqueId;

      id = this.uniqueId;
      const newF: IIsComponent = {
        id: id,
        name: 'menuhandler',
        type: 'menuhandler',
        flexsize: 100,
        flexorder: 1,
        parentid: parentid,
        config: {},
        //place: { },
        //attribs: {"style":{"static":{"":{"float":"none","position":"relative","top":"0px","left":"0px","display":"block","height":"100%","width":"100%"}}}},
        children: [],
        sizes: [],
        //components:[]
      };
      this.editid = id;
      //console.log('start create components');
      //let cft = this.compileFactoryTree([newF]);
      if (!payload.parentid) {
        const renderedtree = await this.formatStructure(newF, null, true);
        Outlet2Service.outletsSubject.next({
          id: -10000,
          component: renderedtree,
        });
        Outlet2Service.formsSubject.next({
          id: -10000,
          component: renderedtree,
        });
        Outlet2Service.formsSubject.next({
          id: parentid,
          component: renderedtree,
        });
        patchState({
          // forms: forms,
          //newcompid: ++newcompid
        });
      }
      if (payload.id) {
        //console.log('end create components');

        //@FIXME: payload.id !== newF.id .... replaces the component in the componentTree
        Outlet2Service.componentTreeSubject.next({
          id: payload.id,
          component: newF,
        });
        patchState({
          // forms: forms,
          //newcompid: ++newcompid
        });
      }
      this.store.dispatch(
        new AddWidget({
          id: this.uniqueId,
          info: {
            type: 'menuhandleritem',
            config: { label: 'layer1', icon: '' },
          },
          parentid: id,
        })
      );
    }
  }

  @Action(ClearOutlet)
  clearOutlet(ctx: StateContext<OutletServiceState>, { payload }: ClearOutlet) {
    Outlet2Service.outletsSubject.next({ id: payload });
  }

  @Action(NewComponent)
  newComponent(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: NewComponent
  ) {
    //console.log('load component',payload);

    return this.ic
      .getData('getid', { key: 'gn_forms', number: 2, account: 10 })
      .pipe(
        tap(async (data) => {
          if (data) {
            if (payload != null) {
              // let parentid = payload.parentid? payload.parentid: this.uniqueId;

              //console.log(`%cClass: Outlet2Service, Function: newComponent.getData(data): `, 'color: red;', data);

              const parentid = payload.parentid
                ? payload.parentid
                : data[0]['id'] - 1;
              let config = {
                gutterSize: 4,
                direction: 'vertical',
                size: 100,
                rootlevel: true,
                formgroup: 1,
              };
              if (payload.config) {
                config = { ...config, ...payload.config };
              }
              const newF: IIsComponent = {
                id: data[0]['id'],
                name: payload.type,
                type: payload.type,
                flexsize: 100,
                flexorder: 1,
                parentid: parentid,
                config: config,
                state: 1,
                //place: { "left": 0, "top": 0  },
                attribs: {
                  draggable: false,
                  style: {
                    static: {
                      '': {
                        float: 'none',
                        position: 'relative',
                        top: '0px',
                        left: '0px',
                        display: 'block',
                        height: '100%',
                        width: '100%',
                      },
                    },
                  },
                },
                children: [],
                sizes: [],
                //components:[]
              };

              this.editid = newF.id;
              //console.log('start create components');
              //let cft = this.compileFactoryTree([newF]);
              if (!payload.parentid) {
                const renderedtree = await this.formatStructure2(
                  newF,
                  null,
                  true,
                  0,
                  0,
                  null,
                  1
                );
                Outlet2Service.outletsSubject.next({
                  id: -10000,
                  component: renderedtree,
                });
                Outlet2Service.formsSubject.next({
                  id: -10000,
                  component: renderedtree,
                });
                Outlet2Service.formsSubject.next({
                  id: newF.parentid,
                  component: renderedtree,
                });
                patchState({
                  //newcompid: ++newcompid
                });
              }
              if (payload.id) {
                // Add component to componenttree
                this.components.set(payload.id, newF);
                Outlet2Service.componentTreeSubject.next({
                  id: newF.parentid,
                  component: newF,
                });
                // Update the component to the canvas
                Outlet2Service.componentTreeSubject.next({
                  id: payload.id,
                  component: newF,
                });
                patchState({
                  // forms: forms,
                  //newcompid: ++newcompid
                });
              }
            }
          }
        })
      );
  }

  @Action(NewId)
  newId({ patchState }: StateContext<OutletServiceState>) {
    return this.ic
      .getData('getid', { key: 'gn_forms', number: 1, account: 10 })
      .pipe(
        tap((data) => {
          if (data) {
            patchState({
              newid: data[0].id,
            });
          }
        })
      );
  }

  @Action(AddWidget)
  async addWidget(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: AddWidget
  ) {
    alert('Action is removed');

    let tree = this.componentTree.get(1_000_000);
    const formPath = getTreeChildPath(
      tree,
      { idProperty: 'id', childrenProperty: 'children' },
      Number(payload.parentid)
    );

    if (!formPath) {
      console.error(`Path not found for "${payload.parentid}"`);
      return;
    }
    // const form = this.findIdx(tree, Number(payload.parentid), 0);
    let form = getOr(tree, formPath, tree);

    const newComponent: IIsComponent = {
      id: payload.id ?? this.uniqueId,
      //component: payload.info.type,
      type: payload.info.type,
      config: payload.info.config,
      name: generateTreeChildPropertyValue(
        tree,
        { indexProperty: 'name', childrenProperty: 'children' },
        payload.info.type
      ),
      attribs: payload.info.attribs,
      parentid: payload.parentid,
      flexsize: 1,
      comptype: payload.info.comptype,
      flexorder: (form?.children?.length ?? 0) + 1,
    };

    if (newComponent.type == 'placeholder'){
      newComponent.cmpref = null;
      newComponent.children = [];
    }

    
    const comp = await this.compService.createWidget(newComponent);
    if (comp?.instance.controlname) {
      newComponent.config.controlname = comp.instance.controlname;
    }
    form = produce(form, (draft) => {
      draft.children = [...(draft.children ?? []), newComponent];
    });

    const comptype = this.compService.widgets.get(payload.parentid).instance
      .type;

    if (comptype == 'menuhandler' || comptype == 'menuhandleritem') {
      let parent = this.compService.widgets.get(payload.parentid);

      if (
        parent.instance.children ? parent.instance.children.length == 0 : true
      ) {
        parent.instance.children = [];
        parent.instance.children.push({ id: payload.id });
      }
      while (parent.instance.type == 'menuhandleritem') {
        parent = this.compService.widgets.get(parent.instance.parentid);
      }

      parent.instance.onLoaded();
    }

    



    // newComponent.parent = form;
    if (tree.id === form.id) {
      tree = form;
    } else {
      tree = set(formPath, form, tree);
    }
    // tree.state = 1;

    Outlet2Service.componentTreeSubject.next({
      id: 1_000_000,
      component: tree,
    });

    const componentsMap = new Map(getState().components);
    componentsMap.set(newComponent.id, newComponent);
    this.components.set(newComponent.id, newComponent);

    patchState({
      components: componentsMap,
      savestate: 1,
    });

    if (this.compService.widgets.get(payload.id).instance.onaftercomponentadd) {
      this.compService.widgets
        .get(payload.id)
        .instance.onaftercomponentadd(newComponent);
    }
    //}}))
  }

  @Action(LoadFormInEditor)
  loadFormInEditor(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload: input }: LoadFormInEditor
  ) {
    this.editid = input.id;
   
    this.getComponentById$(1_000_000)
      .pipe(
        // tap((iic) => {
        //   console.log('loadformineditor ', iic);
        // }),
        filter((iic) => iic?.id === input.id),
        take(1)
      )
      .subscribe((s) => {
        //console.log('loadformineditor [SetCurrentFormDefinition] ', s);
        this.store.dispatch({
          type: '[FormDefinition.SetCurrentFormDefinition]',
          payload: s,
        });
      });

    //this.store.dispatch(new SetComponentEditState({ id: data.id, state: 1 }));
    this.store.dispatch({
      type: '[SetBuilderCanvas]',
      payload: input.parentid,
    });
  }

  @Action(DeleteWidgetOutlet)
  deleteWidget(
    { patchState }: StateContext<OutletServiceState>,
    { payload }: DeleteWidgetOutlet
  ) {
    return Outlet2Service.componentTree$.pipe(
      take(1),
      map((componentTreeMap) => componentTreeMap.get(1_000_000)),
      tap((componentTree) => {
        let workingTree = set(['state'], 1, componentTree);
        const removedWidgetPath = getTreeChildPath(
          workingTree,
          { idProperty: 'id', childrenProperty: 'children' },
          payload
        );
        if (removedWidgetPath && removedWidgetPath.length) {
          const parentWidgetPath = removedWidgetPath.slice(0, -2);
          const [removedWidgetIdx] = <[number]>removedWidgetPath.slice(-1);
          const removedWidgetWithSiblings = get(
            [...parentWidgetPath, 'children'],
            workingTree
          );
          const newChildrenInParent = [
            ...removedWidgetWithSiblings.slice(0, removedWidgetIdx),
            ...removedWidgetWithSiblings.slice(removedWidgetIdx + 1),
          ];
          workingTree = set(
            [...parentWidgetPath, 'children'],
            newChildrenInParent,
            workingTree
          );
          this.deletewidgets.push({ id: payload });
          Outlet2Service.componentTreeSubject.next({
            id: 1_000_000,
            component: workingTree,
          });
          patchState({
            savestate: 1,
          });
        } else {
          this.deletewidgets.push({ id: payload });
        }
      })
    );
  }

  saveForm(form: IIsComponent,newids?: Map<number,number>) {
    const data: any = [];
    const events: any = [];
    const refreshlist: number[] = [];

    //console.log(`%cClass: Outlet2Service, Function: saveForm(form): `, 'color: black;', form);

    const componentArray: Array<IIsComponent> = flattenTree(
      form,
      'children'
    ).map((comp) => ({
      ...comp,
      config: null,
      config_fas: comp.config,
      attribs: null,
      events: comp.events,
      //attribs_fas: comp.attribs,
      attribs_fas: {
        ...comp.attribs,
        style: this.componentStyling(newids?newids.get(comp.id):comp.id),
      },
    }));

    const toRemove: number[] = [];
    const placeHolderIndices: number[] = [];
    const placeHolders = [];

    componentArray.forEach((c) => {

      if (c.placeholderid) {
        placeHolders.push(c);
        placeHolderIndices.push(c.id);
      }

      if ([...placeHolderIndices, ...toRemove].includes(c.parentid)) {
        toRemove.push(c.id);
      }
    });

    const componentArrayFiltered = componentArray.filter((c) => !toRemove.includes(c.id))
      .map((c) => {
        if (placeHolderIndices.includes(c.id)) {
          const { id, type, placeholderid, ...compinfo } = c;
          
          return {
            id: placeholderid,
            type: 'isplaceholder',
            ...compinfo,
            events:null            
          };
        }

        return c;
      });

    console.log(componentArrayFiltered);

    // let i = 0;
    // console.log(componentArray);

    // while (i<componentArray.length) {
    //   if (componentArray[i].placeholderid){

    //     const { id, type, placeholderid, ...compinfo } = componentArray[i];

    //     componentArrayFiltered.push({
    //       id: placeholderid,
    //       type: 'isplaceholder',
    //       ...compinfo,
    //       events:null
    //     })

    //     const { level } = componentArray[i];
    //     i++

    //     while(i<componentArray.length && level <= componentArray[i].level) {
    //       i += 1;
    //     }
    //   } else {
    //     componentArrayFiltered.push(componentArray[i]);
    //     i++;
    //   }
    // }

    const componentArrayWithEvents = componentArrayFiltered.reduce<Array<IIsComponent>>(
      (acc, componentWithEvents) => {
        //console.log(`%cClass: Outlet2Service, Function: saveForm(): `, 'color: black;', componentWithEvents);
        const events =
          componentWithEvents.events?.map((event) => ({
            ...event,
            config_fas: event.config,
            config: null,
          })) ?? [];
        const flattenComponentWithEvents = flattenTree(
          <IIsComponent>{
            ...componentWithEvents,
            events,
          },
          'events'
        );
        return [...acc, ...flattenComponentWithEvents];
      },
      []
    );

    console.log(componentArrayWithEvents);

    //throw Error('niet hier voorbij');

    return componentArrayWithEvents.filter((c) => ![...placeHolderIndices, ...toRemove].includes(c.parentid));
  }

  exportForm(form: IIsComponent) {
    const data: any = [];
    const events: any = [];
    const refreshlist: number[] = [];

    //console.log(`%cClass: Outlet2Service, Function: saveForm(form): `, 'color: black;', form);

    const componentArray: Array<IIsComponent> = flattenTree(
      form,
      'children'
    ).map((comp) => ({
      ...comp,
      events: comp.events,
      attribs: {
        ...comp.attribs,
        style: this.componentStyling(comp.id),
      },
    }));

    const componentArrayWithEvents = componentArray.reduce<Array<IIsComponent>>(
      (acc, componentWithEvents) => {
        //console.log(`%cClass: Outlet2Service, Function: saveForm(): `, 'color: black;', componentWithEvents);
        const events =
          componentWithEvents.events?.map((event) => ({
            ...event,
            config: event.config,
          })) ?? [];
        const flattenComponentWithEvents = flattenTree(
          <IIsComponent>{
            ...componentWithEvents,
            events,
          },
          'events'
        );
        return [...acc, ...flattenComponentWithEvents];
      },
      []
    );

    return componentArrayWithEvents;
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  savevalidator(id, parentid, funcname, funcsrc, savelist) {
    if (!this.isEmpty(funcsrc)) {
      const src = {};
      for (const [key, value] of Object.entries(funcsrc)) {
        if (!key.includes('modified'))
          if (value) {
            if (value !== '') {
              src[key] = value;
            }
          }
      }
      if (!this.isEmpty(src)) {
        const func = {
          id: id,
          parentid: parentid,
          name: funcname,
          type: 'isvalidator',
          config: src,
          flexsize: 100,
          flexorder: 1000,
        };
        savelist.push(func);
      }
    }
  }

  componentStyling(id) {
    const defaultStyles = this.compService.defaultStylesSub.get(id);
    const hoverStyles = this.compService.hoverStylesSub.get(id);

    const styles = {};

    if (defaultStyles) {
      styles['static'] = {};
      defaultStyles.forEach((val, key) => {
        styles['static'][key] = val;
      });
    }
    if (hoverStyles) {
      styles['hover'] = {};
      hoverStyles.forEach((val, key) => {
        styles['hover'][key] = val;
      });
    }

    return styles;
  }

  public async addWidgetToParent({
    widget,
    parentId,
  }: {
    widget: IIsComponent;
    parentId: number;
  }) {
    const renderedTree = await this.formatStructure2(widget, null, false, 0, 0);
    if (!renderedTree) {
      return;
    }
    const parent = this.compService.widgets.get(parentId);
    const lastContainer = parent?.instance.children.last
      ? parent?.instance.children.last
      : parent?.instance.children;

    lastContainer.insert(renderedTree.hostView);
    parent?.changeDetectorRef.markForCheck();

    return renderedTree;
  }

  removeWidgetFromParent(id: number, parentId: number) {
    const componentViewRef = this.compService.widgets.get(id)?.hostView;
    if (!componentViewRef) {
      return;
    }
    const parent = this.compService.widgets.get(parentId);
    const firstContainer: ViewContainerRef = parent?.instance.children.first;

    firstContainer.remove(firstContainer.indexOf(componentViewRef));
    parent?.changeDetectorRef.markForCheck();
    this.deletewidgets.push({ id: id });
  }

  async saveWidget(widget: IIsComponent) {
    const data1 = this.saveForm(widget);

    const storeaction = this.ic
      .execute('storecomponents1', {
        id: widget.parentid,
        account: 10,
        data: data1,
        refresh: 1,
        role: '1',
        returnids: true,
        language: 'nl',
        logging: { unimonitor: false },
      })
      .pipe(
        tap((builderforms) => {
          return this.commandlist;
        })
      );
    return Promise.resolve(storeaction);
  }

  @Action(ExportToClipBoard)
  exportToClipBoard(
    { getState }: StateContext<OutletServiceState>,
    { payload }: ExportToClipBoard
  ) {
    const state = getState();
    const componentTree = payload ?? this.componentTree.get(1_000_000);

    const data1 = this.saveForm(componentTree);

    const id = data1[0].parentid;

    const delparams =
      this.deletewidgets.length > 0
        ? {
            account: 10,
            name: 'app.delcomponent',
            data: this.deletewidgets,
            role: '1',
            language: 'nl',
            logging: { unimonitor: state.unimonitor },
          }
        : null;

    const storeparams = {
      id: id,
      account: 10,
      data: data1,
      refresh: 1,
      role: '1',
      returnids: true,
      language: 'nl',
      logging: { unimonitor: state.unimonitor },
    };

    const allparams = delparams ? { ...storeparams, delparams } : storeparams;
    const exportparams = {name: "storecomponents1",action: "exec",id: data1[0].parentid,account: 10,data:allparams}

    const d = JSON.stringify(exportparams);
    navigator.clipboard.writeText(d).catch((error) => {
      console.error('Unable to copy text ', error);
    });
  }

  @Action(PasteFromClipBoard)
  async pastefromClipBoard(
    { getState, patchState, dispatch }: StateContext<OutletServiceState>,
    { payload }: PasteFromClipBoard
  ) {
    const state = getState();
    const componentTreestr = await navigator.clipboard.readText();
    const componentTree = JSON.parse(componentTreestr);

    const refreshlist = [];
    refreshlist.push(new UnLoadComponent(componentTree[0].parentid));
    refreshlist.push(
      new LoadComponent({
        id: componentTree[0].parentid,
        refresh: 1,
        name: 'canvasbuilder',
        outlet: -10000,
        loadas: 1_000_000,
        source: componentTree,
        data: componentTree[0],
        actionafterload: payload.afterpaste,
      })
    );
    // dispatch(this.commandlist);
    dispatch(refreshlist);
  }

  @Action(SaveTree)
  saveTree(
    { getState, patchState, dispatch }: StateContext<OutletServiceState>,
    { payload }: SaveTree
  ) {
    const state = getState();
    patchState({
      savestate: 2,
    });
    const componentTree = payload ?? this.componentTree.get(1_000_000);

    if (payload) {
      // compare test and componenttree -> possible indices to delete
      const test = this.componentTree.get(1_000_000);
    }

    const data1 = this.saveForm(componentTree);

    const id = data1[0].parentid;

    const delparams =
      this.deletewidgets.length > 0
        ? {
            account: 10,
            name: 'app.delcomponent',
            data: this.deletewidgets,
            role: '1',
            language: 'nl',
            logging: { unimonitor: state.unimonitor },
          }
        : null;

    const storeparams = {
      id: id,
      account: 10,
      data: data1,
      refresh: 1,
      role: '1',
      returnids: true,
      language: 'nl',
      logging: { unimonitor: state.unimonitor },
    };

    const allparams = delparams ? { ...storeparams, delparams } : storeparams;

    return this.ic.execute('storecomponents1', allparams).pipe(
      tap(
        (builderforms) => {
          const refreshlist = [];
          
          const refreshAction = { type: '[LoadFormInEditor]', payload: {id:componentTree.id} };
          const nextAction = 
            new LoadComponent({
              id: componentTree.parentid,
              refresh: 1,
              name: 'canvasbuilder',
              outlet: -10000,
              loadas: 1_000_000,
              nextAction: refreshAction
          });

          refreshlist.push(new UnLoadComponent(componentTree.parentid,nextAction));
          //refreshlist.push();

          dispatch(this.commandlist);
          dispatch(refreshlist);
        },
        catchError((error: any) => {
          if (error.status == 0) {
            return of('Network Offline');
          } else {
            this.store.dispatch({
              type: '[AppError]',
              payload: {
                code: error.status,
                source: 'outlet service.savetree',
                message: error.message + '\n ' + error.error.error,
                level: 'error',
              },
            });
            return of('from CatchError');
          }
        })
      )
    );
  }

  @Action(SaveReport)
  saveReport(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: SaveReport
  ) {
    const state = getState();

    return this.ic
      .execute('storecomponents1', {
        id: payload[0].parentid,
        account: 10,
        data: payload,
        refresh: 1,
        role: '1',
        returnids: true,
        language: 'nl',
        logging: { unimonitor: state.unimonitor },
      })
      .pipe(
        tap((builderforms) => {
          const refreshlist = [];
          refreshlist.push(
            new LoadComponent({
              id: payload[0].parentid,
              refresh: 1,
              name: 'canvasbuilder',
              outlet: -10000,
              loadas: 1_000_000,
            })
          );
          this.store.dispatch(refreshlist);
        }),
        catchError((error: any) => {
          if (error.status == 0) {
            return of('Network Offline');
          } else {
            this.store.dispatch({
              type: '[AppError]',
              payload: {
                code: error.status,
                source: 'outlet service.savereport',
                message: error.message + '\n ' + error.error.error,
                level: 'error',
              },
            });
            return of('from CatchError');
          }
        })
      );
  }

  @Action(SaveWizard)
  saveWizard(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: SaveWizard
  ) {
    const state = getState();
    const data1 = <any>[];
    //let data:any[]=classToPlain(payload);

    return this.ic
      .writeData('storecomponents1', {
        data: data1,
        role: '1',
        language: 'nl',
        logging: { unimonitor: state.unimonitor },
      })
      .pipe(tap((builderforms) => {}));
  }

  @Action(SetComponentSaveState)
  setComponentSaveState(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: SetComponentSaveState
  ) {
    const state = getState();

    patchState({
      savestate: payload,
    });
  }

  @Action(SetComponent)
  async setComponent(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: SetComponent
  ) {
    const state = getState();

    if (!payload.baseid) {
      payload.baseid = 10000;
    }
    const form = this.compileFactoryTree(payload.componentlist);

    const renderedtree = await this.formatStructure(form, 0, false);
    renderedtree?.changeDetectorRef.detectChanges();

    const id = payload.loadas != null ? payload.loadas : payload.id;
    Outlet2Service.formsSubject.next({ id, component: renderedtree });
    Outlet2Service.componentTreeSubject.next({ id, component: form });
    Outlet2Service.outletsSubject.next({
      id: payload.outlet,
      component: renderedtree,
    });
    patchState({});
  }

  @Action(UpdateView)
  updateView({ getState, patchState }: StateContext<OutletServiceState>) {
    const state = getState();

    // const outlets = state.outlets;
    patchState({
      // outlets: outlets,
    });
    //console.log('end store components');
  }

  @Action(RemoveComponent)
  removeComponent(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: RemoveComponent
  ) {
    if (payload.id != null) {
      Outlet2Service.formsSubject.next({ id: payload.id });
      patchState({
        // forms: forms,
      });
    }
  }

  @Action(InsertComponent)
  insertComponent(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: InsertComponent
  ) {
    if (payload.id != null) {
      Outlet2Service.componentTreeSubject.next({
        id: payload.id,
        component: payload,
      });
      patchState({});
    }
  }

  @Action(SetNavigator)
  setBuilderCanvas(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: SetNavigator
  ) {
    return Outlet2Service.componentTree$.pipe(
      take(1),
      map((componentTreeMap) => componentTreeMap.get(payload)),
      tap((componentTree) => {
        Outlet2Service.componentTreeSubject.next({
          id: 1_000_000,
          component: componentTree,
        });
      })
    );
    // const state = getState();
    // const cptree = state.componenttree;
    // const tree = cptree.get(payload);
    // cptree.set(1_000_000, tree);
    // patchState({
    //   componenttree: cptree,
    // });
  }

  convertjson(obj: IIsComponent) {
    try {
      if (obj.config && typeof obj.config === 'string') {
        obj.config = JSON.parse(obj.config);
      }

      if (obj.attribs && typeof obj.attribs === 'string') {
        obj.attribs = JSON.parse(obj.attribs);
      }
      if (obj.children) {
        (obj.children as Array<any>).map((a) => {
          this.convertjson(a);
        });
      }
    } catch {
      console.error('Error parsing json ', obj);
    }
    return obj;
  }

  // async storeAssetLocal(path:string,content:string,sessionid:string) {
  //   try {
  //     await db.dbassets.put({
  //       path: path,
  //       hash: sessionid,
  //       content: content
  //     });
  //   } catch (error) {
  //     console.log('fout schrijven db.dbassets ', path);
  //   }
  // }

  async storeComponentLocal(parentid: number, clist: IIsComponent[]) {
    try {
      await db.componentLists.put({
        pid: parentid,
        hash: clist[0].sessionid,
        name: clist[0].name,
        components: clist,
      });
    } catch (error) {
      console.log('fout schrijven cache ', parentid);
    }
  }

  parsetemplate(alias, dsname, parameters: any) {
    for (const [key, value] of Object.entries(parameters)) {
      alias = alias.replace(`{{${key}}}`, value);
    }
    alias = alias.replace(`{{dsname}}`, dsname);
    alias = alias.replaceAll('/', '');
    return alias;
  }

  @Action(PreLoadComponents)
  preloadComponents(
    { getState, patchState, dispatch }: StateContext<OutletServiceState>,
    { payload }: PreLoadComponents
  ) {
    if (!window.navigator.onLine) return;

    const state = getState();
    
    db.componentLists.filter((r:any) => payload.includes(r.pid)).toArray().then(list => {
      const hashmap = list.map((item:any) => item.hash);
      console.log('list : ',hashmap);
      
      this.ic.execute('app.checkhashcomponents', {
        id : 0,
        refresh: null,
        params: {components:hashmap.toString()},
        role: '1',
        runtime:  0,
        language: 'nl',
        logging: { unimonitor: state.unimonitor },
        account: 10,
      }).subscribe(components => {
        console.log('compoents : ',components);

        if (components) {
          components.forEach(item => {
                this.storeComponentLocal(
                           item[0].parentid,
                           item as IIsComponent[]
                );
              })
        }


      // this.ic.execute('app.preloadcomponents', {
      //   id : 0,
      //   refresh: null,
      //   params: {components:payload.toString()},
      //   role: '1',
      //   runtime:  0,
      //   language: 'nl',
      //   logging: { unimonitor: state.unimonitor },
      //   account: 10,
      // }).subscribe((data) => { 
      //   if (data) {
      //     console.log(data);
      //     data.forEach(item => {
      //       this.storeComponentLocal(
      //                  item[0].parentid,
      //                  item as IIsComponent[]
      //       );
      //     })
      //   }
      //  });
      // })

    })
  })
    

  //   payload.forEach(item => {
  //   const request = {
  //     id: item,
  //     refresh: null,
  //     runtime:  0,
  //     role: '1',
  //     language: 'nl',
  //     connectionid: this.connectionid,
  //     account: '',
  //     params: null,
  //     logging: { unimonitor: state.unimonitor },
  //   };
  //   this.ic.execute<IIsComponent>('app.getcomponents', request).subscribe(async (tree) => {
  //       await this.storeComponentLocal(
  //         item,
  //         tree as IIsComponent[]
  //       );
  //   })
  // })

}  
  adjustPlaceholderids(componentArray:Array<IIsComponent>){
    let i = 0;

    const dict_indices:any = {};

    while (i<componentArray.length) {
      if (componentArray[i].placeholderid){

        const { level } = componentArray[i];
        
        dict_indices[(componentArray[i].pid as number)] = {};

        dict_indices[(componentArray[i].pid as number)][(componentArray[i].cmpref as number) + 1] = this.uniqueId;
        componentArray[i].id = dict_indices[(componentArray[i].pid as number)][(componentArray[i].cmpref as number) + 1];
        
        i++

        while(
          i<componentArray.length 
          && 
          (level < componentArray[i].level || (level === componentArray[i].level && ['isfunction', 'setter', 'isevent'].includes(componentArray[i].type)))
        ) {

          if (componentArray[i].placeholderid) {
            dict_indices[(componentArray[i].pid as number)] = {};

            dict_indices[(componentArray[i].pid as number)][(componentArray[i].cmpref as number) + 1] = this.uniqueId;

            componentArray[i].id = dict_indices[(componentArray[i].pid as number)][(componentArray[i].cmpref as number) + 1];            
            componentArray[i].parentid = dict_indices[(componentArray[i].parent_pid as number)][componentArray[i].parentid];
          } else {
            dict_indices[(componentArray[i].pid as number)][componentArray[i].id] = this.uniqueId;
            componentArray[i].id = dict_indices[(componentArray[i].pid as number)][componentArray[i].id];
            componentArray[i].parentid = dict_indices[(componentArray[i].pid as number)][componentArray[i].parentid];
          }

          // if (componentArray[i].placeholderid) {
          //   dict_indices[]
          // }
          
          i += 1;
        }
      } else 
      {
        i++;
      }
    } 
    
  }

  @Action(LoadComponent)
  loadComponent(
    { getState, patchState, dispatch }: StateContext<OutletServiceState>,
    { payload: input }: LoadComponent
  ) {
    //console.log(`%cClass: Outlet2Service, Function: loadComponent(payload): `, 'color: black;', input);

    if (input.id == null || Number(input.id) == -1) {
      return EMPTY;
    }
    // temporary fix for when the payload.id is a string
    const payload = { ...input, id: Number(input.id) };
    const state = getState();
    const loaderrors: { item: string; message: any; code: any; args: any }[] =
      [];
    const comploaded = state.comploaded;

    const inputparams = input.route
      ? input.route?.queryParams
        ? input.route?.queryParams.getValue()
        : input.route
        ? input.route
        : null
      : null;

    this.loadofflinefirst = inputparams ? inputparams?.loadoffline : null;

    comploaded.set(payload.id, false);
    const components = state.components;
    // const componenttree = state.componenttree;
    const refresh = payload.refresh ? payload.refresh : 0;
    const loadedevents = [];
    const functions = state.functions;
    if (payload.contextid) {
      patchState({
        contextid: payload.contextid,
        comploaded: comploaded,
      });
    }

    return Outlet2Service.forms$.pipe(
      map((formMap) => formMap.get(payload.id)),
      take(1),
      switchMap(async (form) => {
        const formsSubmissions: {
            id: number;
            component: ComponentRef<any> | undefined;
          }[] = [],
          outletsSubmissions: {
            id: number;
            component: ComponentRef<any> | undefined;
          }[] = [],
          componentTreeSubmissions: {
            id: number;
            component: IIsComponent | undefined;
          }[] = [];

        if (!form) {
          let params = input.route
            ? input.route?.queryParams
              ? input.route?.queryParams.getValue()
              : input.route
              ? input.route
              : null
            : null;

          if (input.params) {
            params = { ...params, ...input.params };
          }

          const request = {
            id: payload.id,
            refresh: refresh,
            runtime: payload.comptype ? payload.comptype : 0,
            role: '1',
            language: 'nl',
            connectionid: this.connectionid,
            account: '',
            params: params,
            logging: { unimonitor: state.unimonitor },
          };

          if (payload.account) {
            request['account'] = payload.account;
          }

          if (payload.serveronline === false){
            console.log('load component server offline : ',payload.serveronline);
          }

          const keyExists =
            !window.navigator.onLine || this.loadofflinefirst //|| (payload.serveronline === false)
              ? await db.componentLists.get({ pid: payload.id })
              : null;

          if (!window.navigator.onLine && !keyExists){
            throw new Error("Device is offline and component can't be found in offline database");
          }    

          if (!request.id){
            console.log('id is undefinied');
            
          }

          return (
            payload.source
              ? of(payload.source)
              : keyExists
              ? from(
                  db.componentLists.get(payload.id).then((c) => c?.components)
                )
              : this.ic.execute<IIsComponent>('app.getcomponents', request)
          )
            .pipe(
              tap(async (tree) => {
                const component: IIsComponent[] = [];

                if (!keyExists)
                  await this.storeComponentLocal(
                    payload.id,
                    tree as IIsComponent[]
                  );
                console.log('before adjustPlaceholder ',tree);
                this.adjustPlaceholderids(tree);
                console.log('after adjustPlaceholder ',tree);
                tree?.forEach((a) => {
                  components.set(a.id, a);

                  if (a.type === 'isevent') {
                    if (typeof a.config === 'string') {
                      a.config = JSON.parse(a.config);
                    }
                    let func;
                    try {
                      func = new Function(a.config.code);

                      const eventmap = new EventMap(
                        a.name,
                        a.config.type,
                        func,
                        a.id,
                        this.overlay,
                        this.widgetRegistry,
                        a.parentid,
                        a.config.specific,
                        a.config.selectall,
                        this.compService,
                        this.store,
                        this.renderer
                      );
                      loadedevents[a.id] = { map: eventmap, ref: a.config.ref };
                    } catch (err) {
                      this.store.dispatch({
                        type: '[AppError]',
                        payload: {
                          errorcode: 1000,
                          source: 'function : ' + a.name + a.id.toString(),
                          error: err,
                        },
                      });

                      loaderrors.push({
                        item: 'event ' + a.name,
                        message: err,
                        code: a.config.code,
                        args: '',
                      });

                      console.error(
                        'Error in function ',
                        a.name,
                        ' ',
                        a.config.code
                      );
                    }

                    const cmp: IIsComponent | undefined = component.find(
                      (comp) => {
                        return comp.id === a.parentid;
                      }
                    );
                    if (!cmp) {
                      console.log(
                        new Error(
                          `Parent of isevent not found id:${a.id} parentid:${a.parentid} name:${a.name}`
                        )
                      );
                      return;
                    }
                    cmp.events = [...(cmp?.events ?? []), a];
                    //if (!cmp?.events) {
                    //  cmp.events = [];
                    //}
                    //cmp.events.push(a);
                  } else if (
                    a.type === 'isfunction' ||
                    a.type === 'isvalidator' ||
                    a.type === 'ismessage' ||
                    a.type === 'setter'
                  ) {
                    const args: string[] = [];

                    if (a.attribs) {
                      if (typeof a.attribs === 'string') {
                        a.attribs = JSON.parse(a.attribs);
                      }
                      if (a.attribs.args) {
                        a.attribs.args.map((param: string) => {
                          args.push(param);
                        });
                      }
                    }

                    let func;
                    try {
                      if (typeof a.config === 'string') {
                        a.config = JSON.parse(a.config);
                      }
                      if (a.config.params) {
                        args.push(a.config.params);
                      }
                      args.push(a.config.code);
                      let func;
                      try {
                        func = new Function(...args);
                      } catch (error) {
                        this.store.dispatch({
                          type: '[AppError]',
                          payload: {
                            errorcode: 1000,
                            source: 'function : ' + a.name + a.id.toString(),
                            error: error,
                          },
                        });
                        console.error(
                          'Load function ' + a.name + a.id.toString(),
                          error
                        );
                      }

                      let funcs;
                      let handlers;
                      let setters;
                      switch (a.type) {
                        case 'isfunction':
                          funcs = this.compService.functions.get(a.parentid);
                          break;
                        case 'setter':
                          funcs = this.compService.functions.get(a.parentid);
                          setters = this.compService.setters.get(a.parentid);
                          if (!setters) {
                            setters = new Map<string, Function>();
                          }
                          setters.set(a.name, func as Function);
                          this.compService.setters.set(
                            a.parentid,
                            setters
                          );
                          break;  
                        case 'ismessage':
                          handlers = this.compService.messagehandlers.get(
                            a.parentid
                          );
                          if (!handlers) {
                            handlers = new Map<string, Function>();
                          }
                          handlers.set(a.name, func as Function);
                          this.compService.messagehandlers.set(
                            a.parentid,
                            handlers
                          );
                          funcs = this.compService.functions.get(a.parentid);
                          break;
                        default:
                          funcs = this.compService.validators.get(a.parentid);
                      }

                      if (!funcs) {
                        funcs = new Map<string, Function>();
                      }

                      funcs.set(a.name, func as Function);

                      switch (a.type) {
                        case 'isfunction':
                          this.compService.functions.set(a.parentid, funcs);
                          break;
                        case 'setter':
                          this.compService.functions.set(a.parentid, funcs);
                          this.compService.setters.set(a.parentid, setters);
                          break;  
                        case 'ismessage':
                          this.compService.functions.set(a.parentid, funcs);
                          break;
                        default:
                          this.compService.validators.set(a.parentid, funcs);
                      }
                    } catch (err) {
                      loaderrors.push({
                        item: 'function ' + a.name,
                        message: err,
                        code: a.config.code,
                        args: a.attribs,
                      });
                    }

                    const cmp = component.find((comp) => comp.id === a.parentid);
                    if (!cmp?.events) {
                      cmp.events = [];
                    }
                    cmp.events.push(a);
                  } else {
                    a = this.convertjson(a);
                    component.push(a);
                  }
                });
                // ready with loading records

                if (payload.kind) {
                  if (payload.kind === 'Menu') {
                    const menus = new Map(state.menus);
                    const menu = this.compileMenuTree(component);
                    // console.log(
                    //   `%cClass: Outlet2Service, Function: loadComponent(payload, menu): `,
                    //   'color: red;',
                    //   payload,
                    //   menu
                    // );
                    menus.set(payload.id, menu);
                    patchState({
                      menus,
                      savestate: 0,
                    });
                  }
                } else {
                  if (component.length > 0) {
                    //console.log('component : ', component);
                    component[0].config = {...component[0].config,...params};  
                    const form = this.compileFactoryTree(component);
                    const formCopy = form;
                    
                    const renderedtree = await this.formatStructure2(
                      formCopy,
                      functions,
                      false,
                      0,
                      0,
                      payload.data,
                      1
                    );

                    if (payload.isparent){
                      renderedtree.instance['isparent'] = payload.isparent; 
                    }  

                    componentTreeSubmissions.push({
                      id: payload.id,
                      component: form,
                    });

                    formsSubmissions.push({
                      id: payload.id,
                      component: renderedtree,
                    });
                    if (payload.loadas) {
                      const id = payload.loadas;
                      componentTreeSubmissions.push({ id, component: form });
                      formsSubmissions.push({ id, component: renderedtree });
                    }
                    if (payload.outlet != null) {
                      outletsSubmissions.push({
                        id: payload.outlet,
                        component: renderedtree,
                      });
                    }

                    renderedtree?.instance?.oninit;

                    patchState({
                      functions: functions,
                      savestate: 0,
                      loaderrors: loaderrors,
                    });

                    loaderrors?.map((err) =>
                      console.log(err.item, err.message, err.args)
                    );

                    component.forEach((cmp) => {
                      if (cmp.events) {
                        cmp.events.forEach((event) => {
                          if (event.type == 'isevent') {
                            try {
                            this.compService.setEvent(
                              cmp.id,
                              loadedevents[event.id].map,
                              loadedevents[event.id].ref
                            );
                            } catch (error) {
                              console.error('Error loadcomponent setEvent : ',cmp.id,' event id: ',event.id,' event.name ',event.name);
                            }
                          }
                        });
                      }
                    });

                    //const loadedevents = {};
                    // component.forEach((cmp) => {
                    //   const cmpRef = this.compService.widgets.get(cmp.id);
                    //   if (cmpRef?.instance.beforeformReady) {
                    //     cmpRef?.instance?.beforeformReady();
                    //   }
                    // });

                    component.forEach((cmp) => {
                      const cmpRef = this.compService.widgets.get(cmp.id);
                               

                      if (cmpRef?.instance.afterformReady) {
                        try {
                          cmpRef?.instance?.afterformReady(
                            cmpRef?.instance,
                            params
                          );
                        } catch (e) {
                          console.error(e);
                          this.store?.dispatch({
                            type: '[AppError]',
                            payload: {
                              code: 1000,
                              source:
                                'afterformReady : ' +
                                cmpRef?.instance.name +
                                cmpRef?.instance.name.toString(),
                              message: e,
                              level: 'error',
                            },
                          });
                        }
                      }



                    });

                    if (payload.actionafterload) {
                      payload.actionafterload(renderedtree, payload.data, this);
                    }
                  }
                }

                component.forEach((cmp) => {
                  if (state.comploaded.get(cmp.id) == false) {
                    const comploaded = state.comploaded;
                    comploaded.set(cmp.id, true);

                    patchState({
                      comploaded: comploaded,
                    });
                  }
                });

                componentTreeSubmissions.forEach((submission) => {
                  Outlet2Service.componentTreeSubject.next(submission);
                });
                formsSubmissions.forEach((submission) => {
                  Outlet2Service.formsSubject.next(submission);
                });
                outletsSubmissions.forEach((submission) => {
                  Outlet2Service.outletsSubject.next(submission);
                });

                if (payload.nextAction){                  
                  this.store.dispatch(payload.nextAction)
                }
              })
            )
            .toPromise();
        } else {
          // console.log(
          //   `%cClass: Outlet2Service, Function: loadComponent(componenttree.get(payload.id)): `,
          //   'color: black;',
          //   payload.id,
          //   this.componentTree.get(payload.id)
          // );
          // const form = componenttree.get(payload.id);
          const form: IIsComponent | undefined = this.componentTree.get(
            payload.id
          );

          if (payload.loadas) {
            if (payload.loadas !== 1_000_000) {
              const cmp = await this.copyComponent(
                form.parentid,
                form.id,
                0,
                null,
                payload.data
              );
              componentTreeSubmissions.push({
                id: payload.loadas,
                component: cmp.form,
              });
              formsSubmissions.push({
                id: payload.loadas,
                component: cmp.renderedtree,
              });
              //if(payload.outlet>=0){
              if (payload.outlet != null) {
                outletsSubmissions.push({
                  id: payload.outlet,
                  component: cmp.renderedtree,
                });
              }
            } else {
              if (payload.outlet != null) {
                outletsSubmissions.push({
                  id: payload.outlet,
                  component: this.compService.widgets.get(form?.id),
                });
              }
              componentTreeSubmissions.push({
                id: payload.loadas,
                component: form,
              });
              patchState({
                savestate: form?.state,
              });
            }
          } else {
            const widget = this.compService.widgets.get(form.id);

            // widget?.instance.data = payload.data;

            if (payload.outlet != null) {
              outletsSubmissions.push({
                id: payload.outlet,
                component: widget,
              });
            }
            const event = new CustomEvent('beforereloadinoutlet', {
              bubbles: true,
              cancelable: true,
              detail: { widget: widget },
            });
            widget?.location.nativeElement.dispatchEvent(event);
            patchState({
              // outlets: outlets,
            });
            if (payload.actionafterload) {
              payload.actionafterload(widget, payload.data);
            }
          }
        }
        componentTreeSubmissions.forEach((submission) => {
          Outlet2Service.componentTreeSubject.next(submission);
        });
        formsSubmissions.forEach((submission) => {
          Outlet2Service.formsSubject.next(submission);
        });
        outletsSubmissions.forEach((submission) => {
          Outlet2Service.outletsSubject.next(submission);
        });
        return EMPTY;
      }),
      catchError((error: any) => {
        if (error.status == 0) {
          return of('Network Offline');
        } else {
          this.store.dispatch({
            type: '[AppError]',
            payload: {
              code: error?.status,
              source: 'appstate.loadcomponent',
              message: error?.message + '\n ' + error?.error?.error,
              level: 'error',
            },
          });
          return of('from CatchError');
        }
      })
    );
  }

  @Action(UnLoadComponent)
  unLoadComponent(
    { patchState }: StateContext<OutletServiceState>,
    { payload, nextAction }: UnLoadComponent
  ) {
    this.store.dispatch(new ClearCanvas());
    Outlet2Service.componentTreeSubject.next({ id: payload });
    Outlet2Service.formsSubject.next({ id: payload });
    this.compService.widgets.delete(payload);
    this.compService.events.delete(-100);
    this.compService.functions.delete(-100);
    // const form = forms.get(payload);
    // if (form) {
    //   if (forms.delete(payload)) {
    //     this.compService.widgets.delete(form.instance.id);
    //   }
    // }
    patchState({
      // forms: forms,
      savestate: -1,
    });

    if (nextAction) {
      this.store.dispatch(nextAction)
    }
  }

  @Action(StoreAssetLocal)
  storeAssetLocal({ getState }: StateContext<OutletServiceState>,{ payload }: StoreAssetLocal) {    
    return db.dbassets.put({
        path: payload.path,
        hash: payload.hash,
        content: payload.content
      });
  } 

  @Action(RefreshComponent)
  refreshComponent(
    { getState }: StateContext<OutletServiceState>,
    { payload }: RefreshComponent
  ) {
    const state = getState();
    return this.ic
      .execute('app.refreshcomponent', {
        id: payload,
        role: '1',
        language: 'nl',
        account: 10,
        logging: { unimonitor: state.unimonitor },
      })
      .pipe(
        tap((data) => {
          const refreshlist = [];
          const refreshAction = { type: '[LoadFormInEditor]', payload: {id:payload + 1} };
          const nextAction = new LoadComponent({
            id: payload,
            refresh: 1,
            name: 'canvasbuilder',
            outlet: -10000,
            loadas: 1_000_000,
            nextAction: refreshAction
          });
          //refreshlist.push();
          this.store.dispatch(new UnLoadComponent(payload,nextAction));
        })
      );
  }

  
  @Action(RefreshAllComponents)
  refreshAllComponent({ getState }: StateContext<OutletServiceState>, {payload}: RefreshAllComponents) {
    const state = getState();
    return this.ic
      .execute('app.refreshallcomponents', {        
        role: '1',
        language: 'nl',
        account: 10,
        logging: { unimonitor: state.unimonitor },
      })
      .pipe(
        tap((data) => {
          const refreshlist = [];
          refreshlist.push(new UnLoadComponent(payload));
          refreshlist.push(
            new LoadComponent({
              id: payload,
              refresh: 1,
              name: 'canvasbuilder',
              outlet: -10000,
              loadas: 1_000_000,
            })
          );
          this.store.dispatch(refreshlist);
        })
      );
  }

  @Action(TrackLoading)
  TrackLoading(
    { getState, patchState }: StateContext<OutletServiceState>,
    { payload }: TrackLoading
  ) {
    const state = getState();
    const comploaded = state.comploaded;

    // comploaded.set(payload, false);
    comploaded.set(payload, comploaded.get(payload) ?? false);

    patchState({
      comploaded: comploaded,
    });
  }

  @Action(SetFormConnectionId)
  SetFormConnectionId({ payload }: SetFormConnectionId) {
    this.connectionid = payload?.connectionid ?? null;
  }

  ngOnDestroy() {
    this.#subs.unsubscribe();
  }
}
