import { ComponentFactory, InjectionToken } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';

export const FEATURE_WIDGET_REGISTRY_TOKEN = new InjectionToken<
  Record<string, ComponentType<unknown>>
>('FEATURE_WIDGET_REGISTRY_TOKEN');
export const FEATURE_WIDGET_COMPONENT_FACTORY_REGISTRY_TOKEN =
  new InjectionToken<Record<string, ComponentFactory<unknown>>>(
    'FEATURE_WIDGET_COMPONENT_FACTORY_REGISTRY_TOKEN'
  );
export const WIDGET_REGISTRY_FORROOT_GUARD = new InjectionToken<void>(
  'WIDGET_REGISTRY_FORROOT_GUARD'
);

export type KnownModule =
  //| 'IscompModule'
  //'IsChartsModule'
  | 'HtmleditorModule'
  //| 'IsMonacoEditorModule'
  | 'IsEditorModule'
  | 'IsdocviewerModule'
  | 'IsWebcamModule'
  | 'IswordModule'
  | 'IsexcelModule'
  | 'IstensorModule'
  | 'QrcodeModule'
 // | 'SvgobjectsModule'
  | 'FastreportModule'
  | 'IsFormBuilderModule';

interface RegistryContent {
  module: KnownModule;
  components: Array<string>;
}

export interface LazyRegistryResponse {
  registries: Array<RegistryContent>;
}
