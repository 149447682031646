import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgmemoComponent } from './svgmemo/svgmemo.component';
import { WidgetsRegistryModule } from '@ic-builder/widgets-registry';
import { SvgcompComponent } from './svgcomp/svgcomp.component';

@NgModule({
  imports: [CommonModule,
    WidgetsRegistryModule.forChild({
    //module: { name: 'SvgobjectsModule' },
    module: { ngModule: SvgobjectsModule },
    components: {
      svgmemo: SvgmemoComponent,
      svgcomp: SvgcompComponent,
    }})],
  declarations: [
    SvgmemoComponent,
    SvgcompComponent
  ],
  id:'svgobjects'
})
export class SvgobjectsModule {
  constructor(injector: Injector) {
    //console.log(`%cClass: IsMonacoEditorModule, Function: constructor(injector): `, 'color: black;', injector);
  }
}
