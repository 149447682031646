import { Component, ElementRef, OnInit } from '@angular/core';
import { IsCloudService,ConfigService, LoginResponse } from '@ic-builder/data-access-iscloud';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Component({
  selector: 'ic-builder-svgmemo',
  templateUrl: './svgmemo.component.html',
  styleUrls: ['./svgmemo.component.scss']
})
export class SvgmemoComponent implements OnInit {

  _url:string='';

  set url(value:string){
    if (value){
      this._url = value;
      const res = this.loaddata() //.subscribe(item => console.log(item));
      res.subscribe((r) => this.el.nativeElement.innerHTML = r);
    }
  };

  constructor(private ic:IsCloudService,private config:ConfigService,private http: HttpClient,private el:ElementRef) { }

  ngOnInit(): void {
    // if (this.url){
      
    //   const res = this.loaddata() //.subscribe(item => console.log(item));
    //   console.log(typeof res, res);

    //   res.subscribe((r) => this.el.nativeElement.innerHTML = r);
    // } 
  }

  loaddata(){
    const l = this.config.getDbUrl('') + this._url;
       return this.http.get(l,{
      headers: new HttpHeaders().set('Content-Type', 'svg=image/svg+xml'),
      responseType: 'text'
    })



    // .pipe(
    //   map((response) => {
    //     console.log('response',response);  
    //     return response                  
    //   })
    // );
  
  }
}
