import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { AngularSplitModule } from 'angular-split';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsWebsocketPluginModule } from '@ngxs/websocket-plugin';
import { environment } from '../environments/environment';
import { FormComponent } from './form/form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsRegistryModule } from '@ic-builder/widgets-registry';
import { AuthGuard, AuthModule } from '@ic-builder/auth';
import { FormViewerComponent } from './form-viewer/form-viewer.component';
import { IscompModule } from '@ic-builder/iscomp';
import { SvgobjectsModule } from '@ic-builder/svgobjects';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { IsChartsModule } from '@ic-builder/is-charts';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PromptComponent } from './prompt-component/prompt-component.component';
import { DataAccessIscloudModule } from '@ic-builder/data-access-iscloud';


const appRoutes: Routes = [
  //{ path: '', component: MainComponent, canActivate: [AuthGuard] },

  { path: '', pathMatch: 'full', component: FormComponent },
  // { path: 'getreport' },
  {
    path: 'edit',
    loadChildren: () =>
      import('@ic-builder/is-form-builder').then((m) => m.IsFormBuilderModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'form/:cid',
    component: FormViewerComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('@ic-builder/is-form-builder').then((m) => m.IsFormBuilderModule),
  },
  {
    path: '/workorderpdf',
    redirectTo: '/form/20488',
    canActivate: [AuthGuard],
    //loadChildren: () => import('@ic-builder/is-form-builder').then((m) => m.IsFormBuilderModule),
  },  
  {
    path: 'report/:cid?page=preview',
    component: FormViewerComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@ic-builder/is-form-builder').then((m) => m.IsFormBuilderModule),
  },
  {
    path: 'form/32082',
    component: FormViewerComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@ic-builder/is-form-builder').then((m) => m.IsFormBuilderModule),
  },
];

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    FormViewerComponent,
    PromptComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //IsBaseModule,
    WidgetsRegistryModule.forRoot(),
    AuthModule.forRoot(),
    DataAccessIscloudModule.forRoot({ production: environment.production }),
    IscompModule,
    SvgobjectsModule,
    IsChartsModule,
    // IsWebcamModule,
    // IsFormBuilderModule,
    NgxsModule.forRoot([]),
    NgxsWebsocketPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    RouterModule.forRoot(appRoutes, {
      enableTracing: true,
    }),
    //MonacoEditorModule.forRoot(),
    AngularSplitModule,
    MonacoEditorModule.forRoot(),
    ServiceWorkerModule.register('ic-service-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
