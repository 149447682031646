<svg
   viewBox="0 0 410.00001 406.81238">
  <defs
     id="defs4">
    <linearGradient
       id="linearGradient4161">
      <stop
         style="stop-color:#e9e9a1;stop-opacity:1"
         offset="0"
         id="stop4163" />
      <stop
         style="stop-color:#dcdc60;stop-opacity:1"
         offset="1"
         id="stop4165" />
    </linearGradient>
    <linearGradient
       xlink:href="#linearGradient4161"
       id="linearGradient4167"
       x1="328.08224"
       y1="427.12909"
       x2="346.86435"
       y2="450.64029"
       gradientUnits="userSpaceOnUse" />
    <filter
       style="color-interpolation-filters:sRGB"
       id="filter3591"
       x="0"
       y="0"
       width="1"
       height="1">
      <feGaussianBlur
         stdDeviation="6"
         in="SourceGraphic"
         result="result0"
         id="feGaussianBlur3593" />
      <feDiffuseLighting
         lighting-color="#ffffff"
         diffuseConstant="1"
         surfaceScale="4"
         result="result5"
         id="feDiffuseLighting3595">
        <feDistantLight
           elevation="45"
           azimuth="235"
           id="feDistantLight3597" />
      </feDiffuseLighting>
      <feComposite
         k4="0"
         k3="0"
         k2="0"
         k1="1.4"
         in2="SourceGraphic"
         in="result5"
         result="fbSourceGraphic"
         operator="arithmetic"
         id="feComposite3599" />
      <feGaussianBlur
         result="result0"
         in="fbSourceGraphic"
         stdDeviation="6"
         id="feGaussianBlur3601" />
      <feSpecularLighting
         specularExponent="25"
         specularConstant="1"
         surfaceScale="4"
         lighting-color="#f4d8d8"
         result="result1"
         in="result0"
         id="feSpecularLighting3603">
        <feDistantLight
           azimuth="235"
           elevation="45"
           id="feDistantLight3605" />
      </feSpecularLighting>
      <feComposite
         k4="0"
         k1="0"
         k3="1"
         k2="1"
         operator="arithmetic"
         in="fbSourceGraphic"
         in2="result1"
         result="result4"
         id="feComposite3607" />
      <feComposite
         operator="in"
         result="result2"
         in2="SourceGraphic"
         in="result4"
         id="feComposite3609" />
    </filter>
    <linearGradient
       xlink:href="#linearGradient4289"
       id="linearGradient4287"
       x1="283.95834"
       y1="445.63168"
       x2="350.92413"
       y2="516.63171"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.23435119,0,0,0.23307791,63.982245,2.0660009)" />
    <linearGradient
       id="linearGradient4289">
      <stop
         style="stop-color:#e30000;stop-opacity:1"
         offset="0"
         id="stop4295" />
      <stop
         id="stop4293"
         offset="0.42441505"
         style="stop-color:#eb0000;stop-opacity:1;" />
      <stop
         style="stop-color:#fe0000;stop-opacity:1"
         offset="1"
         id="stop4291" />
    </linearGradient>
    <radialGradient
       xlink:href="#linearGradient4271"
       id="radialGradient4277"
       cx="49.022663"
       cy="617.35596"
       fx="49.022663"
       fy="617.35596"
       r="90.419113"
       gradientTransform="matrix(0.99549505,0,0,0.79302994,15.097996,-241.33907)"
       gradientUnits="userSpaceOnUse"
       spreadMethod="pad" />
    <linearGradient
       id="linearGradient4271">
      <stop
         style="stop-color:#fe0000;stop-opacity:1"
         offset="0"
         id="stop4273" />
      <stop
         id="stop4279"
         offset="0.61115956"
         style="stop-color:#eb0000;stop-opacity:1;" />
      <stop
         style="stop-color:#e30000;stop-opacity:1"
         offset="1"
         id="stop4275" />
    </linearGradient>
  </defs>
  
<g id="g5471">
    <g
       transform="matrix(1.138591,0,0,1.138591,-12.078873,-12.078872)"
       id="g4704">
      <g
         style="image-rendering:auto"
         transform="rotate(-31.466106,-82.210766,304.04103)"
         id="g4207">
        <path
           id="path4147"
           d="m 69.839434,241.81751 v 225 H 313.58875 l 56.25068,-42.18819 V 241.81751 Z"
           style="fill:#ffff99;fill-opacity:1;fill-rule:evenodd;stroke:none" />
        <path
           id="path4149"
           d="m 313.58875,466.81751 c 4.26456,-13.74772 8.38223,-27.53741 14.55782,-40.73912 5.6503,7.20506 20.59866,7.15853 41.69286,-1.44907 z"
           style="fill:url(#linearGradient4167);fill-opacity:1;fill-rule:evenodd;stroke:none" />
      </g>
      <g
         id="g4695">
        <ellipse
           transform="matrix(0.20425508,-0.11640484,0.11722693,0.20162346,104.22181,78.337199)"
           ry="71.204697"
           rx="89.51403"
           cy="248.24268"
           cx="63.899811"
           id="ellipse3726"
           style="opacity:1;fill:url(#radialGradient4277);fill-opacity:1;stroke:#c30000;stroke-width:4.27065849;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;filter:url(#filter3591);image-rendering:auto" />
        <path
           style="opacity:1;fill:url(#linearGradient4287);fill-opacity:1;stroke:#c30000;stroke-width:0.99999458;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;image-rendering:auto"
           d="m 117.52172,103.01232 c 4.79354,0.20507 10.7838,7.46315 17.27072,17.64442 4.43007,6.95303 10.69517,2.69045 12.79201,0.64875 1.96256,-1.91089 6.00019,-7.47523 -0.88017,-12.07736 -10.07486,-6.73888 -17.21421,-12.894499 -17.27072,-17.644423 l -5.95592,5.714306 z"
           id="rect3717" />
        <ellipse
           style="opacity:1;fill:#fe0000;fill-opacity:1;stroke:#c30000;stroke-width:4.27065849;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;filter:url(#filter3591);image-rendering:auto"
           id="path3715"
           cx="32.698029"
           cy="101.36741"
           rx="71.611221"
           ry="56.963757"
           transform="matrix(0.20425508,-0.11640484,0.11722693,0.20162346,104.22181,78.337199)" />
      </g>
    </g>
    <g
       aria-label="MEMO"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:85.33333588px;line-height:0.48828125;font-family:Andy;-inkscape-font-specification:Andy;text-align:center;letter-spacing:0px;word-spacing:0px;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none"
       id="text5447"
       transform="rotate(-32.616384,-434.05206,-29.135153)">
      <path
         d="m -74.500002,507.97902 q -3.875,6.16667 -7.166667,13.70834 -3.291667,7.5 -3.666667,10.125 -0.25,1.79166 -1.416666,2.33333 -1.166667,0.54167 -4.458334,0.54167 -0.916667,0 -1.708333,-0.75 -0.75,-0.75 -1.958334,-3.20834 -1.458333,-1.33333 -9.666667,-14.25 -2.83333,-4.45833 -3.83333,-5.95833 0.0833,1 0.20833,3.33333 0.79167,13.33334 1.95833,18.91667 1.33334,6.45833 1.33334,8.45833 0,1.45834 -1.45834,2.375 -1.41666,0.875 -3.125,0.875 -3.95833,0 -4.625,-3.70833 -0.66666,-3.75 -1.41666,-16.70833 -0.70834,-12.95834 -0.70834,-19.83334 0,-1.16666 -0.41666,-2.70833 -0.20834,-0.875 -0.20834,-1.25 0,-0.20833 0.0417,-0.41667 0.0833,-1.16666 1.16667,-2.20833 1.95833,-1.95833 2.45833,-2.70833 0.75,-1.125 2,-1.125 0.75,0 1.33333,0.16666 0.58334,0.16667 1,0.54167 0.45834,0.33333 2.41667,3.79167 12.375001,22.16666 16.666668,25.54166 0.75,-2.70833 8.291666,-16.75 4.333334,-8.08333 5.083334,-9.83333 0.791667,-1.79167 2.583333,-2.91667 1.791667,-1.16666 3.916667,-1.16666 2.125,0 3.375,2.83333 1.25,2.83333 1.25,7.5 0,6.83333 2.5,21.70833 1.666667,9.79167 1.666667,12.79167 0,1.29167 -1.25,2.125 -1.208333,0.83333 -2.916667,0.83333 -1.25,0 -2.541666,-0.75 -1.25,-0.79166 -1.833334,-1.91666 -0.791667,-1.5 -2.041667,-13 -1.583333,-13.95834 -2.833333,-17.33334 z"
         id="path5449" />
      <path
         d="m -51.041665,502.77069 q 0,-1.33333 1.583334,-2.375 1.625,-1.04167 4.125,-1.04167 1.625,0 2.708333,0.83334 1.083333,0.79166 1.083333,1.79166 0,0.375 0.04167,2.04167 0.04167,4.29167 0.04167,6.66667 2.541667,-0.58334 8.375,-2.58334 5.458334,-1.91666 6.958334,-1.91666 0.875,0 1.5,1.33333 0.625,1.33333 0.625,2.75 0,1.29167 -6.250001,3.70833 -6.25,2.41667 -11.125,3.45834 0.04167,0 0,5.83333 0,5.79167 1.458334,9 1.458333,3.16667 5.333333,3.16667 2.625,0 8.125,-2.125 5.541667,-2.16667 7.958334,-3.79167 2.041667,-1.41667 3.875,-4.66667 1.958333,-3.41666 4.7916668,-3.41666 2.25,0 2.25,3.41666 0,4.625 -4.6666668,8.625 -4.625,4 -12.958334,6.79167 -8.333333,2.75 -14.208333,2.75 -5.500001,0 -8.375001,-5.125 -2.833333,-5.16667 -2.833333,-14.83333 0,-2.125 -0.08333,-3.91667 l -1.958334,0.0833 q -0.708333,0 -1.125,-1 -0.416667,-1 -0.416667,-2.5 0,-1.125 1.083334,-2.125 1.125,-1 2.5,-1.20833 0,-1.375 -0.166667,-3.875 -0.25,-3.33333 -0.25,-5.75 z m 0.541667,-4.95833 q 0,-3.25 12.666667,-6.5 12.666667,-3.25 16.916667,-3.25 3.5,0 4.541667,0.625 1.083333,0.625 1.083333,2.20833 0,2.41667 -1.083333,3.83333 -1.083333,1.375 -3,1.375 -1.75,0 -6.625,0.375 -0.875,0.0417 -2.958334,0.20834 -2.083333,0.125 -14.333334,2.04166 -4.416666,0.70834 -4.75,0.70834 -1,0 -1.75,-0.41667 -0.708333,-0.45833 -0.708333,-1.20833 z"
         id="path5451" />
      <path
         d="m 40.333342,507.97902 q -3.875001,6.16667 -7.166667,13.70834 -3.291667,7.5 -3.666667,10.125 -0.25,1.79166 -1.416667,2.33333 -1.166667,0.54167 -4.458333,0.54167 -0.916667,0 -1.708334,-0.75 -0.75,-0.75 -1.958333,-3.20834 -1.458333,-1.33333 -9.666667,-14.25 -2.8333335,-4.45833 -3.8333335,-5.95833 0.083333,1 0.2083334,3.33333 0.7916666,13.33334 1.9583333,18.91667 1.3333334,6.45833 1.3333334,8.45833 0,1.45834 -1.4583334,2.375 -1.4166667,0.875 -3.1250001,0.875 -3.9583334,0 -4.62500009,-3.70833 -0.66666669,-3.75 -1.41666671,-16.70833 -0.7083334,-12.95834 -0.7083334,-19.83334 0,-1.16666 -0.4166666,-2.70833 -0.2083334,-0.875 -0.2083334,-1.25 0,-0.20833 0.041667,-0.41667 0.083333,-1.16666 1.1666667,-2.20833 1.9583334,-1.95833 2.4583334,-2.70833 0.75,-1.125 2.0000001,-1.125 0.75,0 1.3333333,0.16666 0.5833334,0.16667 1.0000001,0.54167 0.4583333,0.33333 2.4166667,3.79167 12.3749998,22.16666 16.6666668,25.54166 0.75,-2.70833 8.291667,-16.75 4.333333,-8.08333 5.083333,-9.83333 0.791667,-1.79167 2.583334,-2.91667 1.791667,-1.16666 3.916667,-1.16666 2.125,0 3.375,2.83333 1.25,2.83333 1.25,7.5 0,6.83333 2.5,21.70833 1.666667,9.79167 1.666667,12.79167 0,1.29167 -1.25,2.125 -1.208334,0.83333 -2.916667,0.83333 -1.25,0 -2.541667,-0.75 -1.25,-0.79166 -1.833333,-1.91666 -0.791667,-1.5 -2.041667,-13 -1.583333,-13.95834 -2.833333,-17.33334 z"
         id="path5453" />
      <path
         d="m 61.500012,513.85402 q 0,-6.79166 1.75,-12.625 1.791667,-5.83333 4.916667,-9.54166 3.125,-3.70834 6.041667,-3.70834 1.5,0 2.833333,1.41667 1.333333,1.375 1.333333,2.70833 0,2.91667 -0.75,4.79167 -0.708333,1.83333 -1.541666,1.83333 -1.125,0 -1.458334,-0.66666 -0.375,-0.75 -1,-0.75 -1,0 -1.958333,5.29166 -0.958333,5.29167 -0.958333,13.20834 0,9.70833 2,14.95833 2,5.25 5.333333,5.25 6.208334,0 10.916667,-5.625 4.708334,-5.66667 4.708334,-13.20833 0,-6.41667 -3.916667,-11.91667 -3.916667,-5.5 -9.208334,-7.70833 -2.083333,-0.875 -2.083333,-4.95834 0,-1.33333 0.75,-2.16666 0.791667,-0.83334 2.166667,-0.83334 5.041666,0 9.875,4.04167 4.833333,4 7.833333,10.58333 3.041664,6.58334 3.041664,13.20834 0,5.58333 -3.791664,12 -3.75,6.375 -9.416667,10.125 -5.666666,3.75 -11.875,3.75 -7.875,0 -11.708334,-6.79167 -3.833333,-6.79167 -3.833333,-22.66667 z"
         id="path5455" />
    </g>
  </g>
</svg>

  